import {useState} from 'react';
import {Link} from 'react-router-dom';
import { ResetPassword } from '../API/AuthAPI.js';
import Loader from './Loader.js';

const PasswordReset = (props) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const submitForm = (e) => {
        e.preventDefault();
        submit();
    }
    const submit = () => {
        setLoading(true);
        ResetPassword(props.global, email).then(() => {
            setMessage("Check your email for a temporary password.")
            setLoading(false);
        }).catch(() => {
            let msg = "An error occurred while reseting your password. ";
            msg += "Please try again later.";
            setMessage(msg);
            setLoading(false);
        })
    }
    return (
        <div className="login-container">
        {loading && <Loader />}
        <div className="login-dialog">
            <div className="login-header">Register</div>
                <div className="login-body">
                    <form className="form" onSubmit={(e) => submitForm(e) }>
                        <label>Email:</label>
                            <input type="text" className="text-field" autoComplete="email"
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div className="button-container">
                                <Link to="#" className="button" onClick={submit}>
                                    Reset Password
                                </Link>
                            </div>
                            <div className="button-container">
                                <Link to="/" className="button">
                                    Return to Login
                                </Link>
                            </div>
                            <div className="button-container">
                                <strong>{message}</strong>
                            </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default PasswordReset;