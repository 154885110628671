import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Loader from './Loader.js';
import { GetUserPreferences, SetUserPreferences } from '../API/AccessAPI';
import { ResetPassword, Logout } from '../API/AuthAPI.js';

const Profile = (props) => {
    let history = useHistory();
    const [prefs, setPrefs] = useState([]);
    const [usrType, setUsrType] = useState(0);
    const [usrFN, setUsrFN] = useState("");
    const [usrLN, setUsrLN] = useState("");
    const [acctID, setAcctID] = useState(-1);
    const [usrID, setUsrID] = useState(-1);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [error2, setError2] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        GetUserPreferences(props.global).then((response) => {
            setLoading(false);
            setPrefs(response.userPrefs);
            setUsrType(response.usr.userType);
            setUsrFN(response.usr.firstName);
            setUsrLN(response.usr.lastName);
            setAcctID(response.usr.accountID);
            setUsrID(response.usr.userID);
        })
    }, [props.global])

    const changePreference = (pid) => {
        let newPreferences = [...prefs];
        let pref = newPreferences.find(p =>
            p.prefsID === pid);
        if (pref === undefined)
            return;
        pref.notifs = !pref.notifs;
        setPrefs(newPreferences);
    }

    const save = () => {
        setError("");
        setMessage("");
        setLoading(true);
        SetUserPreferences(props.global, prefs).then((response) => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return
            }
            setMessage("Preferences Saved.");
        }).catch(() => {
            setLoading(false);
            setError("An error occurred.  Please try again later.");
        })
    }

    const submitForm = (e) => {
        e.preventDefault();
        submit();
    }
    const submit = () => {
        setError2("");
        if (email.trim() === "") {
            setError2("Please enter your email."); return;
        }
        setLoading(true);
        ResetPassword(props.global, email).then(() => {
            setMessage("Check your email for a temporary password.")
            setLoading(false);
        }).catch(() => {
            let msg = "An error occurred while reseting your password. ";
            msg += "Please try again later.";
            setError2(msg);
            setLoading(false);
        })
        Logout(props.global).then(() => {
            if (localStorage.getItem("pools-token") !== null)
                localStorage.removeItem("pools-token");
            if (sessionStorage.getItem("pools-token") !== null)
                localStorage.removeItem("pools-token");
            props.global.setAuthenticated(false);
            history.push("/");
        }).catch(() => {
            console.log("Error logging out?!?");
        });
    }

    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Profile</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <h2 className="section-header">Info</h2>
                <div>
                    {usrType === 0 ? (
                        <Link to={`/Accounts/${acctID}`}>
                            {usrLN}, {usrFN}
                        </Link>
                    ) : (
                        <Link to={`/Employees/${usrID}`}>
                            {usrLN}, {usrFN}
                        </Link>
                    )}
                </div>
                <hr />
                <h2 className="section-header">Preferences</h2>
                <div className="pools-table-container">
                    <table className="pools-table">
                        <tbody>
                            <tr>
                                <th>Preference Name</th>
                                <th></th>
                            </tr>
                            {prefs.length === 0 && (
                                <tr>
                                    <td colSpan="2"><i>No preferences found.</i></td>
                                </tr>
                            )}
                            {prefs.map(p =>
                                <tr key={p.prefsID}>
                                    <td>{p.prefsName}</td>
                                    <td>
                                        <Link to="#" className="checkbox"
                                            onClick={() => changePreference(p.prefsID)}>
                                            <i className={p.notifs ? "fa-solid fa-square-check fa-2x" :
                                                "fa-solid fa-square fa-2x"}></i>
                                        </Link>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {prefs.length > 0 && (
                        <div className="button-container">
                            <Link to="#" className="button"
                                onClick={save}>
                                Save Changes
                            </Link>
                        </div>
                    )}
                    <div className="error">{error}</div>
                    <div className="success">{message}</div>
                </div>
                <hr />
                <h2 className="section-header">Password Reset</h2>
                <div className="login-dialog">
                    {/*<div className="login-header">Register</div>*/}
                    <div className="login-body">
                        <form className="form" onSubmit={(e) => submitForm(e)}>
                            <label>Email:</label>
                            <input type="text" className="text-field" autoComplete="email"
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div className="button-container">
                                <Link to="#" className="button" onClick={submit}>
                                    Reset Password
                                </Link>
                            </div>
                            <div className="error">{error2}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile