import {useState} from 'react';
import {Link} from 'react-router-dom';
import {SetPassword} from '../API/AuthAPI.js';
import Loader from './Loader.js';

const SetNewPassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const submitForm = (e) => {
        e.preventDefault();
        submitSetPassword();
    }
    const submitSetPassword = () => {
        setError("");
        if (password.trim().length < 8)
        {
            setError("Password must be at least 8 characters.");
            return;
        }
        if (password.trim() !== confirmPassword.trim())
        {
            setError("Password must match.");
            return;
        }
        setLoading(true);
        SetPassword(props.global, password).then(response => {
            setLoading(false);
            if (response.success)
            {
                props.global.setChangePassword(false);
            }
        }).catch(() => {
            setLoading(false);
            setError("An error occurred.  Please try again later.")
        })
    }
    return (
        <div className="login-container">
            {loading && <Loader />}
            <div className="login-dialog">
            <div className="login-header">Change Password</div>
            <div className="login-body">
                <form className="form" onSubmit={(e) => submitForm(e) }>
                <label>Password:</label>
                <input type="password" className="text-field" autoComplete="password"
                    value={password} onChange={(e) => setPassword(e.target.value)} />
                <label>Confirm Password:</label>
                <input type="password" className="text-field" autoComplete="password"
                    value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <input type="submit" value="" className="form-submit" />
                <div className="button-container">
                    <Link to="#" className="button" onClick={() => submitSetPassword()}>
                    Set New Password</Link>
                </div>
                <div className="error">{error}</div>
                </form>
            </div>
            </div>
        </div>
    )
}

export default SetNewPassword