import {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {GetEmployee, SaveEmployee} from '../API/EmployeeAPI';
import Loader from './Loader.js';

const Employee = (props) => {
    let {id} = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [canEdit, setCanEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phoneTypes, setPhoneTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [displayName, setDisplayName] = useState("Loading...");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [active, setActive] = useState(true);
    const [roleId, setRoleId] = useState(0);
    const [primaryPhone, setPrimaryPhone] = useState("");
    const [primaryPhoneType, setPrimaryPhoneType] = useState(0);
    const [primaryPhoneTypeName, setPrimaryPhoneTypeName] = useState("");
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [secondaryPhoneType, setSecondaryPhoneType] = useState(0);
    const [secondaryPhoneTypeName, setSecondaryPhoneTypeName] = useState("");
    
    useEffect(() => {
        setLoading(true);
        GetEmployee(props.global, id).then((response) => {
            if (!response.success)
            {
                history.push('/Employees');
                setLoading(false);
                return;
            }
            // console.log(response);
            setPhoneTypes(response.phoneTypes);
            setRoles(response.roles);
            setCanEdit(response.canAddEdit);
            let empInfo = response.employee;
            setDisplayName("New Employee");
            setLoading(false);
            if (id === -1)
            {
                setMode("edit");
                return;
            }
            setDisplayName(`${empInfo.lastName}, ${empInfo.firstName}`)
            setFirstName(empInfo.firstName);            
            setLastName(empInfo.lastName);
            setEmail(empInfo.email);
            setActive(empInfo.active);
            setRoleId(empInfo.roleID);
            setPrimaryPhone(empInfo.primaryPhone);
            setPrimaryPhoneType(empInfo.primaryPhoneTypeID);
            setPrimaryPhoneTypeName(empInfo.primaryPhoneTypeName);
            setSecondaryPhone(empInfo.secondaryPhone);
            setSecondaryPhoneType(empInfo.secondaryPhoneTypeID);
            setSecondaryPhoneTypeName(empInfo.secondaryPhoneTypeName);
        })
    }, [props.global, id, history, mode])
    const cancelClick = () => {
        if (id === -1)
        {
            history.push('/Employees');
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        save();
    }
    const save = () => {
        setLoading(true);
        setError("");
        SaveEmployee(props.global, id,
            firstName, lastName, primaryPhone,
            primaryPhoneType, secondaryPhone,
            secondaryPhoneType, email, active,
            roleId).then(response => {
                setLoading(false);
                if (!response.success)
                {
                    setError(response.message);
                    return;
                }
                if (id === -1)
                {
                    history.push(`/Employees/${response.userID}`);
                }
                setMode("view");
        }).catch(() => {
            setLoading(false);
            setError("An error occurred.  Please Try again later.");
        });
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Employees" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e) }>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>First Name:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field" value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)} required />
                                    ) : firstName}
                                </td>
                            </tr>
                            <tr>
                                <th>Last Name:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field" value={lastName}
                                            onChange={(e) => setLastName(e.target.value)} required />
                                    ) : lastName}
                                </td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field email-field" value={email}
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    ) : email}
                                </td>
                            </tr>
                            <tr>
                                <th>Active:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <Link to="#" className="checkbox" 
                                            onClick={() => setActive(!active)}>
                                            <i className={active ? "fa-solid fa-square-check fa-2x" :
                                                "fa-solid fa-square fa-2x"}></i>
                                        </Link>
                                    ) : active ? "Yes" : "No"}
                                </td>
                            </tr>
                            <tr>
                                <th>Role:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <select className="select" value={roleId} 
                                            onChange={(e) => setRoleId(e.target.value)}>
                                            {roles.map((r) => 
                                                <option key={r.roleID} value={r.roleID}>
                                                    {r.name}
                                                </option>
                                            )}
                                        </select>
                                    ) : roles.find(r => r.roleID === roleId)?.name}
                                </td>
                            </tr>
                            <tr>
                                <th>Primary Phone:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <div className="phone-input-container">
                                            <PhoneInput
                                                placeholder=""
                                                defaultCountry='US'
                                                country="US"
                                                value={primaryPhone}
                                                onChange={setPrimaryPhone} />
                                            <select value={primaryPhoneType} className="select phone-select"
                                                onChange={(e) => setPrimaryPhoneType(e.target.value)}>
                                                {phoneTypes.map(p => 
                                                    <option key={"p-" + p.phoneTypeID} value={p.phoneTypeID}>
                                                        {p.phoneTypeName}
                                                    </option>    
                                                )}
                                            </select>
                                        </div>
                                        
                                    ) : 
                                        primaryPhone + " (" + primaryPhoneTypeName + ")"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Secondary Phone:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <div className="phone-input-container">
                                            <PhoneInput
                                                placeholder=""
                                                defaultCountry='US'
                                                country="US"
                                                value={secondaryPhone}
                                                onChange={setSecondaryPhone} />
                                            <select value={secondaryPhoneType} className="select phone-select"
                                                onChange={(e) => setSecondaryPhoneType(e.target.value)}>
                                                {phoneTypes.map(p => 
                                                    <option key={"s-" + p.phoneTypeID} value={p.phoneTypeID}>
                                                        {p.phoneTypeName}
                                                    </option>    
                                                )}
                                            </select>
                                        </div>
                                        
                                    ) : 
                                        (secondaryPhone ?? "") + " (" + secondaryPhoneTypeName + ")"
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className="button-container">
                    {mode !== "edit" && canEdit && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {mode === "edit" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            <Link to="#" className="button"
                                onClick={save}>
                                Save
                            </Link>
                        </>
                    )}
                </div>
                <div className="error">{error}</div>
            </div>
        </div>
    )
}
export default Employee;