import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetTaskTemp, CreateTaskTemp, SaveTaskTemp } from '../API/InstallationAPI';
import Loader from './Loader.js';

const TaskTemp = (props) => {
    let { id } = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [taskTempName, setTaskTempName] = useState("");
    const [timeVal, setTimeVal] = useState(0.0);
    const [unitValId, setUnitValId] = useState(0);
    //const [unitValName, setUnitValName] = useState("");
    const [statusId, setStatusId] = useState(0);
    //const [statusName, setStatusName] = useState("");
    //const [canAdd, setCanAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [timeUnits, setTimeUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [displayName, setDisplayName] = useState("New Task Template");
    useEffect(() => {
        setLoading(true);
        GetTaskTemp(props.global, id).then(response => {
            setLoading(false);
            if (!response.success) {
                history.push('/Permissions');
                return;
            }
            if (response.success) {
                setTimeUnits(response.timeUnits);
                setStatuses(response.installStatuses);
                if (id === -1) {
                    setMode("create");
                    return;
                }
                let taskTemp = response.taskTemp;
                setTaskTempName(taskTemp.taskTempName);
                setTimeVal(taskTemp.timeVal);
                setUnitValId(taskTemp.unitValID);
                //setUnitValName(taskTemp.unitValName);
                setStatusId(taskTemp.statusID);
                //setStatusName(taskTemp.statusName);
                //setCanAdd(response.canAddEdit);
                setMode("edit");
                setDisplayName("Task Template");
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, id, history, mode])
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (taskTempName.trim() === "") {
            setError("A template name is required."); return;
        }
        if (timeVal === 0) {
            setError("Task length is required."); return;
        }
        setLoading(true);
        SaveTaskTemp(props.global, id, taskTempName, timeVal, unitValId, statusId).then(response => {
            if (!response.success) {
                setError(response.message);
                return;
            }
            history.push("/Permissions");
        }).catch(() => {
            setLoading(false);
            setError("Error occurred while saving template.  Please try again later.");
        })
    }
    const submit = () => {
        setError("");
        if (taskTempName.trim() === "") {
            setError("A template name is required."); return;
        }
        if (timeVal === 0) {
            setError("Task length is required."); return;
        }
        setLoading(true);
        CreateTaskTemp(props.global, id, taskTempName, timeVal, unitValId, statusId).then(response => {
            if (!response.success) {
                setError(response.message);
                return;
            }
            history.push("/Permissions");
        }).catch(() => {
            setLoading(false);
            setError("Error occurred while creating template.  Please try again later.");
        })
    }
    const handleUnitValSelect = (e) => {
        setUnitValId(e.target.value);
    }
    const handleStatusSelect = (e) => {
        setStatusId(e.target.value);
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Permissions" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e)}>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Template Name:</th>
                                <td>
                                    <input className="text-field" value={taskTempName} onChange={(e) => setTaskTempName(e.target.value)} required />
                                </td>
                            </tr>
                            <tr>
                                <th>Task Length:</th>
                                <td>
                                    <input type="number" className="text-field" value={timeVal} onChange={(e) => setTimeVal(e.target.value)} required min="0" />
                                    <select className="select" value={unitValId} onChange={(e) => handleUnitValSelect(e)}>
                                        {timeUnits.map(tu =>
                                            <option key={tu.timeID} value={tu.timeID}>
                                                {tu.timeName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Status:</th>
                                <td>
                                    <select className="select" value={statusId} onChange={(e) => handleStatusSelect(e)}>
                                        {statuses.map(s =>
                                            <option key={s.statusID} value={s.statusID}>
                                                {s.statusName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="error">{error}</div>
                    <div className="button-container">
                        <Link to="/Permissions" className="button">
                            Cancel
                        </Link>
                        {mode === "create" ?
                            (
                                <Link to="#" className="button"
                                    onClick={submit}>
                                    Submit
                                </Link>
                            ) :
                            (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default TaskTemp;