import {Link} from 'react-router-dom';
import AccountAddress from './Account/AccountAddress.js';
import AccountHolders from './Account/AccountHolders.js';
import AccountInstallation from './Account/AccountInstallation.js';
import AccountService from './Account/AccountService.js';

const Account = (props) => {
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Accounts" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>Customer Account</h1>
            </div>
            <div className="display-area-body">
                <h2>Address</h2>
                <AccountAddress global={props.global} />
                <h2>Account Holders</h2>
                <AccountHolders global={props.global} />
                <h2>Installation</h2>
                <AccountInstallation global={props.global} />
                <h2>Service</h2>
                <AccountService global={props.global} />
            </div>
        </div>
    )
}
export default Account;