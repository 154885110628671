import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetAttachList } from '../API/InstallationAPI';
import Loader from './Loader.js';

const AttachList = (props) => {
    const [canSeeInternal, setCanSeeInternal] = useState(false);
    const [canSeeInactive, setCanSeeInactive] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [flag, setFlag] = useState(-1);
    const [flags, setFlags] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetAttachList(props.global, props.instId, props.servID, props.acctID, props.noteID, flag, showInactive).then(response => {
            setLoading(false);
            if (response.success) {
                //console.log(response);
                setAttachments(response.attachments);
                setFlags(response.flags);
                setCanSeeInactive(response.canSeeInactive);
                setCanSeeInternal(response.canSeeInternal);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, props.instId, props.acctID, props.servID, props.noteID, flag, showInactive])
    const handleFilter = (e) => {
        setFlag(e.target.value);
    }
    const { apiBaseURL } = window['runConfig'];

    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                {((props.instId !== -1 && props.instId !== null && props.instId !== undefined) && props.noteID === -1) && (
                    <Link to={`/Installations/${props.instId}/Attachments/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Attachment
                    </Link>
                )}
                {((props.instId !== -1 && props.instId !== null && props.instId !== undefined) && props.noteID !== -1) && (
                    <Link to={`/Installations/${props.instId}/Notes/${props.noteID}/Attachments/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Attachment
                    </Link>
                )}
                {(props.acctID !== -1 && props.acctID !== null && props.acctID !== undefined) && (
                    <Link to={`/Accounts/${props.acctID}/Attachments/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Attachment
                    </Link>
                )}
                {((props.servID !== -1 && props.servID !== null && props.servID !== undefined) && props.noteID === -1) && (
                    <Link to={`/Services/${props.servID}/Attachments/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Attachment
                    </Link>
                )}
                {((props.servID !== -1 && props.servID !== null && props.servID !== undefined) && props.noteID !== -1) && (
                    <Link to={`/Services/${props.servID}/Notes/${props.noteID}/Attachments/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Attachment
                    </Link>
                )}
                {canSeeInternal === true && (
                    <div className="dropdown-area">
                        <select value={flag} className="select flag-select"
                            onChange={(e) => handleFilter(e)}>
                            <option value={-1}>All Flags</option>
                            {flags.map(f =>
                                <option key={"f-" + f.flagID} value={f.flagID}>
                                    {f.flagName}
                                </option>
                            )}
                        </select>
                    </div>
                )}
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Attachment Name</th>
                        <th>Description</th>
                        <th>User</th>
                        <th>Upload Date</th>
                        {canSeeInternal === true && (
                            <th>Flag</th>
                        )}
                    </tr>
                    {attachments.length === 0 && (
                        <tr>
                            <td colSpan="5"><i>No Attachments Found.</i></td>
                        </tr>
                    )}
                    {attachments.map(a =>
                        <tr key={"a-" + a.attachmentID}>
                            <td>
                                <a href={`${apiBaseURL}/api/Installation/GetFile?FileId=${a.attachmentID}`}>
                                    {a.attachName}
                                </a>
                            </td>
                            <td>
                                {((props.instId !== -1 && props.instId !== undefined) && props.noteID === -1) && (
                                    <Link to={`/Installations/${props.instId}/Attachments/${a.attachmentID}`}>
                                        {a.attachDescript}
                                    </Link>
                                )}
                                {((props.instId !== -1 && props.instId !== undefined) && props.noteID !== -1) && (
                                    <Link to={`/Installations/${props.instId}/Notes/${props.noteID}/Attachments/${a.attachmentID}`}>
                                        {a.attachDescript}
                                    </Link>
                                )}
                            {/* uncomment this when ready to do accounts */}
                                {/*{(props.acctID !== -1) && (*/}
                                {/*    <Link to={`/Accounts/${props.acctID}/Attachments/${a.attachmentID}`}>*/}
                                {/*        {a.attachDescript}*/}
                                {/*    </Link>*/}
                                {/*)}*/}
                                {((props.servID !== -1 && props.servID !== undefined) && props.noteID === -1) && (
                                    <Link to={`/Services/${props.servID}/Attachments/${a.attachmentID}`}>
                                        {a.attachDescript}
                                    </Link>
                                )}
                                {((props.servID !== -1 && props.servID !== undefined) && props.noteID !== -1) && (
                                    <Link to={`/Services/${props.servID}/Notes/${props.noteID}/Attachments/${a.attachmentID}`}>
                                        {a.attachDescript}
                                    </Link>
                                )}
                            </td>
                            <td>
                                {a.userFN} {a.userLN}
                            </td>
                            <td>
                                {a.uploadTime_fmt}
                            </td>
                            {canSeeInternal === true && (
                                <td>
                                    {a.flagName}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default AttachList;