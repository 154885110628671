import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//<reference path="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" />
//import $ from "jquery";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
//const token = localStorage.getItem("pools-token");
const startApp = () => {
  ReactDOM.render(
    <HashRouter basename={baseUrl}>
      <App />
    </HashRouter>,
    rootElement);
}
if (window.cordova)
{
    //let isAndroid = false;
    //if (window.cordova.platformId !== "ios") {
    //    isAndroid = true;
    //}
    //document.addEventListener('deviceready', () => {
    //    startApp();
    //    window.cordova.plugins.FCM.getToken(function (tkn) {
    //        $.post(`${baseUrl}/api/Dashboard/SetDeviceTokens`,
    //            { deviceToken: tkn, isAndroidDevice: isAndroid },
    //            function (response) {
    //                return response;
    //            })
    //    });
    //}, false);
    document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
