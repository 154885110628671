import APIRequest from './APIRequest.js';


export const GetServiceCallList = (global, status, acctID, pageNum, pageSize) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/GetServiceCallList',
        method: 'post',
        body: {
            "pageNum": pageNum,
            "pageSize": pageSize,
            "status": status,
            "acctID": acctID
        }
    })
}

export const GetServiceCall = (global, serviceID) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/GetServiceCall',
        method: 'post',
        body: {
            "serviceID": serviceID
        }
    })
}

export const CreateServiceCall = (global, serviceId, svcDescript, svcDate, arrivalDate, expectDepartDate, departDate, accountId, svcType, svcStatus, svcTempId, waterClarity,
    origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA, departPumpPressure, departCL, departPH, departTA, departCA, svcSchedule, userIDs) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/CreateServiceCall',
        method: 'post',
        body: {
            "svcCall": {
                "ServiceID": serviceId,
                "SvcDescript": svcDescript,
                "SvcDate_S": svcDate,
                "ArrivalDate_S": arrivalDate,
                "EDD_S": expectDepartDate,
                "DepartDate_S": departDate,
                "AccountID": accountId,
                "SvcTypeID": svcType,
                "SvcStatusID": svcStatus,
                "SvcTempID": svcTempId,
                "WaterClarity": waterClarity,
                "OrigPumpPressure": origPumpPressure,
                "BackWashed": backWashed,
                "SkimmerBsktsClean": skimmerBsktsClean,
                "OrigCL": origCL,
                "OrigPH": origPH,
                "OrigTA": origTA,
                "OrigCA": origCA,
                "DepartPumpPressure": departPumpPressure,
                "DepartCL": departCL,
                "DepartPH": departPH,
                "DepartTA": departTA,
                "DepartCA": departCA,
                "SvcScheduleID": svcSchedule
            },
            "svcCallUsers": userIDs
        }
    })
}

export const SaveServiceCall = (global, serviceId, svcDescript, svcDate, arrivalDate, expectDepartDate, departDate, accountId, svcType, svcStatus, svcTempId, waterClarity,
    origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA, departPumpPressure, departCL, departPH, departTA, departCA, svcSchedule, userIDs) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/SaveServiceCall',
        method: 'post',
        body: {
            "svcCall": {
                "ServiceID": serviceId,
                "SvcDescript": svcDescript,
                "SvcDate_S": svcDate,
                "ArrivalDate_S": arrivalDate,
                "EDD_S": expectDepartDate,
                "DepartDate_S": departDate,
                "AccountID": accountId,
                "SvcTypeID": svcType,
                "SvcStatusID": svcStatus,
                "SvcTempID": svcTempId,
                "WaterClarity": waterClarity,
                "OrigPumpPressure": origPumpPressure,
                "BackWashed": backWashed,
                "SkimmerBsktsClean": skimmerBsktsClean,
                "OrigCL": origCL,
                "OrigPH": origPH,
                "OrigTA": origTA,
                "OrigCA": origCA,
                "DepartPumpPressure": departPumpPressure,
                "DepartCL": departCL,
                "DepartPH": departPH,
                "DepartTA": departTA,
                "DepartCA": departCA,
                "SvcScheduleID": parseInt(svcSchedule)
            },
            "svcCallUsers": userIDs
        }
    })
}

export const GetServiceCallTempList = (global, status) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/GetServiceCallTempList',
        method: 'post',
        body: {
            "status": status
        }
    })
}

export const GetServiceCallTemp = (global, svcTempID) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/GetServiceCallTemp',
        method: 'post',
        body: {
            "svcTempID": svcTempID
        }
    })
}

export const CreateServiceCallTemp = (global, svcTempID, svcTempName, timeVal, svcUnitVal, scheduleUnitVal, status, svcType) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/CreateServiceCallTemp',
        method: 'post',
        body: {
            "svcTemp": {
                "SvcTempID": svcTempID,
                "SvcTempName": svcTempName,
                "TimeVal": timeVal,
                "ServiceUnitValID": svcUnitVal,
                "ScheduleUnitValID": scheduleUnitVal,
                "StatusID": status,
                "SvcTypeID": svcType
            }
        }
    })
}

export const SaveServiceCallTemp = (global, svcTempID, svcTempName, timeVal, svcUnitVal, scheduleUnitVal, status, svcType) => {
    return APIRequest({
        global: global,
        url: 'ServiceCall/SaveServiceCallTemp',
        method: 'post',
        body: {
            "svcTemp": {
                "SvcTempID": svcTempID,
                "SvcTempName": svcTempName,
                "TimeVal": timeVal,
                "ServiceUnitValID": svcUnitVal,
                "ScheduleUnitValID": scheduleUnitVal,
                "StatusID": status,
                "SvcTypeID": svcType
            }
        }
    })
}