import APIRequest from './APIRequest.js';

export const GetInstallList = (global, statuses, pageNum, pageSize) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetInstallList',
        method: 'post',
        body: {
            "pageNum": pageNum,
            "pageSize": pageSize,
            "status": statuses
        }
    })
}

export const GetInstall = (global, installID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetInstall',
        method: 'post',
        body: {
            "installID": installID
        }
    })
}

export const CreateInstall = (global, installId, accountId, startDate, expectCompDate, compDate, status, userIDs) => {
    return APIRequest({
        global: global,
        url: 'Installation/CreateInstall',
        method: 'post',
        body: {
            "install": {
                "InstallID": installId,
                "AccountID": accountId,
                "StartDate_S": startDate,
                "ECDate_S": expectCompDate,
                "CompleteDate_S": compDate,
                "InstallStatusID": status
            },
            "installUsers": userIDs
        }
    })
}

export const SaveInstall = (global, installId, accountId, startDate, expectCompDate, compDate, status, userIDs) => {
    //console.log(userIDs);
    return APIRequest({
        global: global,
        url: 'Installation/SaveInstall',
        method: 'post',
        body: {
            "install": {
                "InstallID": installId,
                "AccountID": accountId,
                "StartDate_S": startDate,
                "ECDate_S": expectCompDate,
                "CompleteDate_S": compDate,
                "InstallStatusID": status
            },
            "installUsers": userIDs
        }
    })
}

export const GetTaskList = (global, installID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetTaskList',
        method: 'post',
        body: {
            "installID": installID
        }
    })
}

export const GetTask = (global, taskID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetTask',
        method: 'post',
        body: {
            "taskID": taskID
        }
    })
}

export const CreateTask = (global, taskID, name, startDate, expectDate, completeDate, installID, taskTempID, status, taskUsers) => {
    return APIRequest({
        global: global,
        url: 'Installation/CreateTask',
        method: 'post',
        body: {
            "task": {
                "TaskID": taskID,
                "TaskName": name,
                "StartDate_S": startDate,
                "ECDate_S": expectDate,
                "CompleteDate_S": completeDate,
                "InstallID": installID,
                "TaskTempID": taskTempID,
                "TaskStatusID": status
            },
            "taskUsers": taskUsers
        }
    })
}

export const SaveTask = (global, taskID, name, startDate, expectDate, completeDate, installID, taskTempID, status, taskUsers) => {
    return APIRequest({
        global: global,
        url: 'Installation/SaveTask',
        method: 'post',
        body: {
            "task": {
                "TaskID": taskID,
                "TaskName": name,
                "StartDate_S": startDate,
                "ECDate_S": expectDate,
                "CompleteDate_S": completeDate,
                "InstallID": installID,
                "TaskTempID": taskTempID,
                "TaskStatusID": status
            },
            "taskUsers": taskUsers
        }
    })
}

export const GetTaskTempList = (global, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetTaskTempList',
        method: 'post',
        body: {
            "status": status
        }
    })
}

export const GetTaskTemp = (global, taskTempID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetTaskTemp',
        method: 'post',
        body: {
            "taskTempID": taskTempID
        }
    })
}

export const CreateTaskTemp = (global, taskTempID, taskTempName, timeVal, unitVal, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/CreateTaskTemp',
        method: 'post',
        body: {
            "taskTemp": {
                "TaskTempID": taskTempID,
                "TaskTempName": taskTempName,
                "TimeVal": timeVal,
                "UnitValID": unitVal,
                "StatusID": status
            }
        }
    })
}

export const SaveTaskTemp = (global, taskTempID, taskTempName, timeVal, unitVal, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/SaveTaskTemp',
        method: 'post',
        body: {
            "taskTemp": {
                "TaskTempID": taskTempID,
                "TaskTempName": taskTempName,
                "TimeVal": timeVal,
                "UnitValID": unitVal,
                "StatusID": status
            }
        }
    })
}

export const GetAttachList = (global, installID, svcID, acctID, noteID, flag, includeInactive) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetAttachList',
        method: 'post',
        body: {
            "installID": installID,
            "svcID": svcID,
            "acctID": acctID,
            "noteID": noteID,
            "flag": flag,
            "includeInactive": includeInactive
        }
    })
}

export const GetAttach = (global, attachID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetAttach',
        method: 'post',
        body: {
            "attachID": attachID
        }
    })
}

export const DeleteAttach = (global, attachID, attachName, attachUpload, attachDescript, flag, uploadTime, userID, installID, noteID, svcID, acctID, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/DeleteAttach',
        method: 'post',
        body: {
            "attach": {
                "AttachmentID": attachID,
                "AttachName": attachName,
                "AttachUpload": attachUpload,
                "AttachDescript": attachDescript,
                "FlagID": flag,
                "UploadTime_S": uploadTime,
                "UserID": userID,
                "InstallID": installID,
                "NoteID": noteID,
                "ServiceID": svcID,
                "AccountID": acctID,
                "StatusID": status
            }
        }
    })
}

export const CreateAttach = (global, attachID, attachName, attachUpload, attachDescript, flag, uploadTime, userID, installID, noteID, svcID, acctID, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/CreateAttach',
        method: 'post',
        body: {
            "attach": {
                "AttachmentID": attachID,
                "AttachName": attachName,
                "AttachUpload": attachUpload,
                "AttachDescript": attachDescript,
                "FlagID": flag,
                "UploadTime_S": uploadTime,
                "UserID": userID,
                "InstallID": installID,
                "NoteID": noteID,
                "ServiceID": svcID,
                "AccountID": acctID,
                "StatusID": status
            }
        }
    })
}

export const SaveAttach = (global, attachID, attachName, attachUpload, attachDescript, flag, uploadTime, userID, installID, noteID, svcID, acctID, status) => {
    return APIRequest({
        global: global,
        url: 'Installation/SaveAttach',
        method: 'post',
        body: {
            "attach": {
                "AttachmentID": attachID,
                "AttachName": attachName,
                "AttachUpload": attachUpload,
                "AttachDescript": attachDescript,
                "FlagID": flag,
                "UploadTime_S": uploadTime,
                "UserID": userID,
                "InstallID": installID,
                "NoteID": noteID,
                "ServiceID": svcID,
                "AccountID": acctID,
                "StatusID": status
            }
        }
    })
}

export const GetNoteList = (global, includeInactive, installID, taskID, svcID, flag) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetNoteList',
        method: 'post',
        body: {
            "includeInactive": includeInactive,
            "installID": installID,
            "taskID": taskID,
            "svcID": svcID,
            "flag": flag
        }
    })
}

export const GetNote = (global, noteID) => {
    return APIRequest({
        global: global,
        url: 'Installation/GetNote',
        method: 'post',
        body: {
            "noteID": noteID
        }
    })
}

export const CreateNote = (global, noteID, noteName, noteDescript, noteTime, taskID, installID, userID, svcID, status, flag) => {
    return APIRequest({
        global: global,
        url: 'Installation/CreateNote',
        method: 'post',
        body: {
            "note": {
                "NoteID": noteID,
                "NoteName": noteName,
                "NoteDescript": noteDescript,
                "noteTime_S": noteTime,
                "TaskID": taskID,
                "InstallID": installID,
                "UserID": userID,
                "SvcID": svcID,
                "NoteStatusID": status,
                "FlagID": flag
            }
        }
    })
}

export const SaveNote = (global, noteID, noteName, noteDescript, noteTime, taskID, installID, userID, svcID, status, flag) => {
    return APIRequest({
        global: global,
        url: 'Installation/SaveNote',
        method: 'post',
        body: {
            "note": {
                "NoteID": noteID,
                "NoteName": noteName,
                "NoteDescript": noteDescript,
                "noteTime_S": noteTime,
                "TaskID": taskID,
                "InstallID": installID,
                "UserID": userID,
                "SvcID": svcID,
                "NoteStatusID": status,
                "FlagID": flag
            }
        }
    })
}