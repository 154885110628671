import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetTaskTempList } from '../API/InstallationAPI';
import Loader from './Loader.js';

const TaskTempList = (props) => {
    const [status, setStatus] = useState(-1);
    const [taskTemps, setTaskTemps] = useState([]);
    //const [canAdd, setCanAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    //const [timeUnits, setTimeUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetTaskTempList(props.global, status).then(response => {
            setLoading(false);
            if (response.success) {
                setTaskTemps(response.taskTemplates);
                /*setTimeUnits(response.timeUnits);*/
                setStatuses(response.installStatuses);
                //setCanAdd(response.canAddEdit);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, status])
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                <Link to="/TaskTemps/new" className="button">
                    <i className="fa-solid fa-circle-plus"></i> Add New Template
                </Link>
            {/* will be dropdown list for statuses to filter task temps - look at install list */}
                <select className="select" value={status}
                    onChange={(e) => setStatus(e.target.value)}>
                    <option value={-1}>-- Select a Status --</option>
                    {statuses.map(s =>
                        <option key={s.statusID} value={s.statusID}>
                            {s.statusName}
                        </option>
                    )}
                </select>
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Task Template Name</th>
                        <th>Status</th>
                    </tr>
                    {taskTemps.length === 0 && (
                        <tr>
                            <td colSpan="2"><i>No Task Templates Found.</i></td>
                        </tr>
                    )}
                    {status !== -1 && (
                        taskTemps.filter(tt => tt.statusID === status).map(t =>
                            <tr key={t.taskTempID}>
                                <td>
                                    <Link to={`/TaskTemps/${t.taskTempID}`}>
                                        {t.taskTempName}
                                    </Link>
                                </td>
                                <td>
                                    {t.statusName}
                                </td>
                            </tr>
                        )
                    )}
                    {taskTemps.map(t =>
                        <tr key={t.taskTempID}>
                            <td>
                                <Link to={`/TaskTemps/${t.taskTempID}`}>
                                    {t.taskTempName}
                                </Link>
                            </td>
                            <td>
                                {t.statusName}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default TaskTempList;