import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetTask, CreateTask, SaveTask } from '../API/InstallationAPI';
import NoteList from './NoteList.js';
import Loader from './Loader.js';
import Select from "react-select";

const Task = (props) => {
    let { instID, taskID } = useParams();
    let history = useHistory();
    if (taskID === "new")
        taskID = -1;
    const [taskName, setTaskName] = useState("");
    const [startDate, setStartDate] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [startDate2, setStartDate2] = useState();
    const [startDateTime, setStartDateTime] = useState();
    const [startDate_fmt, setStartDateFmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [expectCompDate, setExpectCompDate] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [expectCompDate2, setExpectCompDate2] = useState();
    const [expectCompDateTime, setExpectCompDateTime] = useState();
    const [expectCompDate_fmt, setExpectCompDate_fmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [completeDate, setCompleteDate] = useState(null);
    const [completeDate2, setCompleteDate2] = useState(null);
    const [completeDateTime, setCompleteDateTime] = useState(null);
    const [completeDate_fmt, setCompleteDate_fmt] = useState(null);
    const [installId, setInstallId] = useState(-1);
    const [taskTempId, setTaskTempId] = useState(-1);
    const [status, setStatus] = useState(0);
    const [statusName, setStatusName] = useState("Pending");
    const [empNames, setEmpNames] = useState([]);   // current assigned emps
    const [anEmpName, setAnEmpName] = useState(null);
    const [emps, setEmps] = useState([]);   // list of all emps
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [canSeeEmps, setCanSeeEmps] = useState(false);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [displayName, setDisplayName] = useState("Loading...");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetTask(props.global, taskID).then(response => {
            setLoading(false);
            if (!response.success) {
                history.push(`/Installations/${instID}`);
                return;
            }
            let taskInfo = response.task;
            setEmps(response.emps);
            setTaskStatuses(response.taskStatuses);
            setCanEdit(response.canAddEdit);
            setCanSeeEmps(response.canSeeEmps);
            setInstallId(instID);
            setStartDate(response.currDate);
            let date = new Date();
            //let day = date.getDate();
            //let month = date.getMonth() + 1
            //let year = date.getFullYear()
            let hour = date.getHours()
            let min = date.getMinutes();
            //month = (month < 10 ? "0" : "") + month;
            //day = (day < 10 ? "0" : "") + day;
            hour = (hour < 10 ? "0" : "") + hour;
            min = (min < 10 ? "0" : "") + min;
            setStartDate2(hour + ":" + min);
            setStartDateTime(response.currDate + ' ' + hour + ":" + min)
            if (taskID === -1) {
                setDisplayName("New Task");
                setMode("create");
                return;
            }
            setDisplayName("Task");
            setTaskName(taskInfo.taskName);
            setStartDate(taskInfo.startDate);
            setStartDate2(taskInfo.startDate2)
            setStartDateTime(taskInfo.startDate + ' ' + taskInfo.startDate2)
            setStartDateFmt(taskInfo.startDate_fmt);
            setExpectCompDate(taskInfo.expectCompleteDate);
            setExpectCompDate2(taskInfo.expectCompleteDate2);
            setExpectCompDateTime(taskInfo.expectCompleteDate + ' ' + taskInfo.expectCompleteDate2)
            setExpectCompDate_fmt(taskInfo.expectCompleteDate_fmt);
            setCompleteDate(taskInfo.completionDate);
            setCompleteDate2(taskInfo.completionDate2)
            setCompleteDateTime(taskInfo.completionDate + ' ' + taskInfo.completionDate2)
            setCompleteDate_fmt(taskInfo.completionDate_fmt);
            setInstallId(taskInfo.installID);
            setTaskTempId(taskInfo.taskTempID);
            setStatus(taskInfo.status);
            setStatusName(taskInfo.statusTypeName);
            setEmpNames(taskInfo.empsNames.map(u => {
                return {
                    value: u.userID, label: u.lastName + ", " + u.firstName
                }
            }))
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, taskID, history, mode, instID])
    var allEmps = emps.map(e => ({ value: e.userID, label: e.lastName + ", " + e.firstName }));
    const cancelClick = () => {
        if (taskID === -1) {
            history.push(`/Installations/${installId}`);
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (taskName.trim() === "") {
            setError("A task name is required."); return;
        }
        if (startDateTime === "" || startDateTime === null || startDateTime === undefined) {
            setError("A start date is required."); return;
        }
        if (expectCompDateTime === "" || expectCompDateTime === null || expectCompDateTime === undefined) {
            setError("An expected completion date is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            SaveTask(props.global, taskID, taskName, startDateTime, expectCompDateTime, completeDateTime, installId, taskTempId, status, empNames.map(e => e.value)).then(response => {
                console.log(response)
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (taskID === -1) {
                    history.push(`/Tasks/${response.taskID}`);
                }
                setMode("view");
            })
        }
        else {
            SaveTask(props.global, taskID, taskName, startDateTime, expectCompDateTime, completeDateTime, installId, taskTempId, status, null).then(response => {
                console.log(response)
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (taskID === -1) {
                    history.push(`/Installations/${response.taskID}`);
                }
                setMode("view");
            })
        }
    }
    const submit = () => {
        setError("");
        if (taskName.trim() === "") {
            setError("A task name is required."); return;
        }
        if (startDateTime === "" || startDateTime === null || startDateTime === undefined) {
            setError("A start date is required."); return;
        }
        if (expectCompDateTime === "" || expectCompDateTime === null || expectCompDateTime === undefined) {
            setError("An expected completion date is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            CreateTask(props.global, taskID, taskName, startDateTime, expectCompDateTime, completeDateTime, installId, taskTempId, status, empNames.map(e => e.value)).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (taskID === -1) {
                    history.push(`/Installations/${response.task.installID}`);
                }
                setMode("view");
            })
        }
        else {
            CreateTask(props.global, taskID, taskName, startDateTime, expectCompDateTime, completeDateTime, installId, taskTempId, status, null).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (taskID === -1) {
                    history.push(`/Installations/${response.task.installID}`);
                }
                setMode("view");
            })
        }
    }
    const handleEmpSelect = (e) => {
        setEmpNames(e);
        setAnEmpName(e);
    }
    const handleStartDate = (e) => {
        setStartDate(e.target.value)
        setStartDateTime(e.target.value + ' ' + startDate2)
    }
    const handleStartTime = (e) => {
        setStartDate2(e.target.value)
        setStartDateTime(startDate + ' ' + e.target.value)
    }
    const handleExpectCompleteDate = (e) => {
        setExpectCompDate(e.target.value)
        setExpectCompDateTime(e.target.value + ' ' + expectCompDate2)
    }
    const handleExpectCompleteTime = (e) => {
        setExpectCompDate2(e.target.value)
        setExpectCompDateTime(expectCompDate + ' ' + e.target.value)
    }
    const handleCompleteDate = (e) => {
        setCompleteDate(e.target.value)
        setCompleteDateTime(e.target.value + ' ' + completeDate2)
    }
    const handleCompleteTime = (e) => {
        setCompleteDate2(e.target.value)
        setCompleteDateTime(completeDate + ' ' + e.target.value)
    }
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="display-area-header">
                {displayName !== "New Task" ? (
                    <Link to={`/Installations/${installId}`} className="header-button">
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                ) : (
                    <Link to="/Installations" className="header-button">
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                <form className="form" onSubmit={(e) => submitForm(e)}>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Task Name</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="text" className="text-field" value={taskName} onChange={(e) => setTaskName(e.target.value)} required />
                                    ): taskName}
                                </td>
                            </tr>
                            <tr>
                                <th>Estimated Start Date</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="date" className="text-field" value={startDate}
                                            onChange={(e) => handleStartDate(e)} required />
                                    ) : startDate_fmt}
                                    {mode !== "view" && (
                                        <input type="time" className="text-field" value={startDate2}
                                            onChange={(e) => handleStartTime(e)} required />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Expected Completion Date</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="date" className="text-field" value={expectCompDate === null ? "" : expectCompDate}
                                            onChange={(e) => handleExpectCompleteDate(e)} required />
                                    ) : expectCompDate_fmt}
                                    {mode !== "view" && (
                                        <input type="time" className="text-field" value={expectCompDate2 === null ? "" : expectCompDate2}
                                            onChange={(e) => handleExpectCompleteTime(e)} required />
                                    )}
                                </td>
                            </tr>
                            {mode !== "create" && (
                                <tr>
                                    <th>Completion Date</th>
                                    <td>
                                        {(completeDate === null && mode === "view") && <i>No completion date.</i>}
                                        {(completeDate !== null && mode === "view" && completeDate_fmt)}
                                        {mode !== "view" && (
                                            <input type="date" className="text-field" value={completeDate}
                                                onChange={(e) => handleCompleteDate(e)} />
                                        )}
                                        {mode !== "view" && (
                                            <input type="time" className="text-field" value={completeDate2}
                                                onChange={(e) => handleCompleteTime(e)} />
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th>Status</th>
                                <td>
                                    {/* creating task auto sets status to pending */}
                                    {mode === "edit" ? (
                                        <div className="status-input-container">
                                            <select value={status} className="select status-select"
                                                onChange={(e) => setStatus(e.target.value)}>
                                                {taskStatuses.map(s =>
                                                    <option key={"st-" + s.statusID} value={s.statusID}>
                                                        {s.statusName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    ) : statusName}
                                </td>
                            </tr>
                            <tr>
                                <th>Assigned Employee(s)</th>
                                {(empNames.length === 0 && mode === "view") && (
                                    <td>
                                        <i>No assigned employees.</i>
                                    </td>
                                )}
                                {(empNames.length !== 0 && mode === "view" && canSeeEmps === true) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                <Link to={`/Employees/${e.value}`}>
                                                    {e.label}
                                                </Link>
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {(empNames.length !== 0 && mode === "view" && canSeeEmps === false) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                <Link to={`/Employees/${e.value}`}>
                                                    {e.label}
                                                </Link>
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {mode === "create" && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)" value={anEmpName === null ? "" : anEmpName} options={allEmps} onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                                {mode === "edit" && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)"
                                            value={empNames}
                                            options={allEmps}
                                            onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className="error">{error}</div>
                <div className="button-container">
                    {mode === "view" && canEdit && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {mode !== "view" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            {mode === "edit" && (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )}
                            {mode === "create" && (
                                <Link to="#" className="button"
                                    onClick={submit}>
                                    Submit
                                </Link>
                            )}
                        </>
                    )}
                </div>
                {mode === "view" && (<hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Notes</h2>
                )}
                {mode === "view" && (
                    <NoteList global={props.global} instId={instID} tskID={taskID} servID={-1} />
                )}
                {mode === "view" && (<br />)}
            </div>
        </div>
    )
}
export default Task;