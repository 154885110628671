import {Switch, Route} from 'react-router-dom';
import Navigation from './Navigation.js';
import Account from './Account.js';
import Accounts from './Accounts.js';
import Installation from './Installation.js';
import Installations from './Installations.js';
import Services from './Services.js';
import Employee from './Employee.js';
import Employees from './Employees.js';
import Permissions from './Permissions.js';
import Dashboard from './Dashboard.js';
import Role from './Role.js';
import CreateAccountForm from './CreateAccountForm.js';
import TaskTemp from './TaskTemp.js';
import Task from './Task.js';
import Note from './Note.js';
import Attach from './Attach.js';
import AccountHolder from './Account/AccountHolder.js';
import ServiceTemp from './ServiceTemp.js';
import Service from './Service.js';
import Profile from './Profile.js';

const AuthorizedRoutes = (props) => {
    return (
        <div className="authorized-routes">
            <Navigation global={props.global} />
            <div className="display-area">
                <Switch>
                    <Route path="/Accounts/new" render={() =>
                        <CreateAccountForm global={props.global} />} />
                    <Route path="/Accounts/:acctID/Attachments/:attachID" render={() =>
                        <Attach global={props.global} />} />
                    <Route path="/Accounts/:accountid/accountholders/:id" render={() =>
                        <AccountHolder global={props.global} />} />
                    <Route path="/Accounts/:id" render={() =>
                        <Account global={props.global} /> } />
                    <Route path="/Accounts" render={() => 
                        <Accounts global={props.global} />} />
                    {/*<Route path="/Installations/:instID/Tasks/:taskID/Notes/:noteID/Attachments/:attachID" render={() =>*/}
                    {/*    <Attach global={props.global} />} />*/}
                    <Route path="/Installations/:instID/Tasks/:taskID/Notes/:noteID" render={() =>
                        <Note global={props.global} />} />
                    <Route path="/Installations/:instID/Tasks/:taskID" render={() =>
                        <Task global={props.global} />} />
                    <Route path="/Installations/:instID/Notes/:noteID/Attachments/:attachID" render={() =>
                        <Attach global={props.global} />} />
                    <Route path="/Installations/:instID/Notes/:noteID" render={() =>
                        <Note global={props.global} />} />
                    <Route path="/Installations/:instID/Attachments/:attachID" render={() =>
                        <Attach global={props.global} />} />
                    <Route path="/Installations/:id" render={() =>
                        <Installation global={props.global} />} />
                    <Route path="/Installations" render={() => 
                        <Installations global={props.global} />} />
                    <Route path="/Services/:svcID/Notes/:noteID/Attachments/:attachID" render={() =>
                        <Attach global={props.global} />} />
                    <Route path="/Services/:svcID/Notes/:noteID" render={() =>
                        <Note global={props.global} />} />
                    <Route path="/Services/:svcID/Attachments/:attachID" render={() =>
                        <Attach global={props.global} />} />
                    <Route path="/Services/:id" render={() =>
                        <Service global={props.global} />} />
                    <Route path="/Services" render={() => 
                        <Services global={props.global} />} />
                    <Route path="/Employees/:id" render={() => 
                        <Employee global={props.global} />} />
                    <Route path="/Employees" render={() => 
                        <Employees global={props.global} />} />
                    <Route path="/Permissions" render={() => 
                        <Permissions global={props.global} />} />
                    <Route path="/Roles/:id" render={() => 
                        <Role global={props.global} />} />
                    <Route path="/TaskTemps/:id" render={() =>
                        <TaskTemp global={props.global} />} />
                    <Route path="/ServiceTemps/:id" render={() =>
                        <ServiceTemp global={props.global} />} />
                    <Route path="/Profile" render={() =>
                        <Profile global={props.global} />} />
                    <Route path="/" render={() => 
                        <Dashboard global={props.global} />} />
                </Switch>
            </div>
        </div>
    )
}

export default AuthorizedRoutes;