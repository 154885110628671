import React, {useState, useMemo, useEffect} from 'react';
import {CheckAuth} from './API/AuthAPI';
import Header from './components/Header.js';
import Loader from './components/Loader.js';
import Footer from './components/Footer.js';
import SetNewPassword from './components/SetNewPassword.js';
import AuthorizedRoutes from './components/AuthorizedRoutes.js';
import UnauthorizedRoutes from './components/UnauthorizedRoutes.js';

import './custom.css'

const App = () => {
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const global = useMemo(() => {
    return {
      setLoading: setLoading,
      setAuthenticated: setAuthenticated,
      setChangePassword: setChangePassword
    }
  }, [setLoading, setAuthenticated]);
  useEffect(() => {
    if (global.authenticated)
      return;
    setLoading(true);
    CheckAuth(global).then((response) => {
      if (response.success)
      {
        setAuthenticated(true);
      }
      setLoading(false);
      setChangePassword(response.changePassword);
    }).catch(() => {
      setLoading(false);
      setAuthenticated(false);
    });
  }, [global])
  return (
    <div className="app">
      {loading && <Loader isGlobal={true} />}
      <Header global={global} authenticated={authenticated} />
      <div className="main">
        { authenticated ? 
            changePassword ? 
              <SetNewPassword global={global} /> : 
          <AuthorizedRoutes global={global} /> :  
          <UnauthorizedRoutes global={global} 
            globalLoading={loading} />
        }
      </div>
      <Footer />
    </div>
  )
}

export default App;