import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Loader from '../Loader';
import { GetAccount } from '../../API/AccountAPI';

const AccountAddress = (props) => {
    let {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("view");
    const [states, setStates] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    useEffect(() => {
        setLoading(true);
        const { apiBaseURL } = window['runConfig'];
        fetch(`${apiBaseURL}/states.json`).then(response =>
            response.json()
        ).then(response => {
            setStates(response)
            return GetAccount(props.global, id)
        }).then(response => {
            setAddress1(response.accountInfo.address1);
            setAddress2(response.accountInfo.address2);
            setCity(response.accountInfo.city);
            setState(response.accountInfo.state);
            setZip(response.accountInfo.zip)
            setCanEdit(response.canEdit);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Error during account loading")
            console.log(err);
            console.trace();
        })
    }, [props.global, id, mode])
    const viewAddress = () => {
        return (
            <div>
                <p>
                    {address1}<br />
                    {address2}
                    {address2 !== "" && <br />}
                    {city}, {state} {zip}
                </p>
                {canEdit && (
                    <p>
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit Address
                        </Link>
                    </p>
                )}
            </div>
        )
    }
    const editAddress = () => {
        return (
            <form className="form">
                <table className="pools-table pools-table-horizontal">
                    <tbody>
                        <tr>
                            <th>Address:</th>
                            <td>
                                <input className="text-field" value={address1}
                                    onChange={(e) => setAddress1(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <th>Address2:</th>
                            <td>
                                <input className="text-field" value={address2}
                                    onChange={(e) => setAddress2(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <th>City:</th>
                            <td>
                                <input className="text-field" value={city}
                                    onChange={(e) => setCity(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <th>State:</th>
                            <td>
                                <select className="select" value={state} 
                                    onChange={(e) => setState(e.target.value)}>
                                    {states.map(s => 
                                        <option key={s.abbreviation} value={s.abbreviation}>
                                            {s.name}
                                        </option>    
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Zip:</th>
                            <td>
                                <input className="text-field" value={zip}
                                    onChange={(e) => setZip(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="button-container">
                    <Link to="#" className="button" onClick={() => setMode("view")}>
                        Cancel
                    </Link>
                    <Link to="#" className="button" onClick={() => setMode("view")}>
                        Save
                    </Link>
                </div>
            </form>
        )
    }
    return (
        <div className="widget-container account-address-container">
            {loading && <Loader />}
            {mode === "view" ? viewAddress() : editAddress()}
        </div>
    )
}
export default AccountAddress