import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {GetPermissionList, SetPermissions} from '../API/AccessAPI';
import Loader from './Loader.js';

const RolePermissions = (props) => {
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(-1);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("")
    const [permissions, setPermissions] = useState([]);
    
    useEffect(() => {
        setLoading(true);
        GetPermissionList(props.global, role).then((response) => {
            setLoading(false);
            setRoles(response.roles);
            setPermissions(response.permissions);
        });
    }, [props.global, role]);
    
    const changePermission = (pid) => {
        let newPermissions = [...permissions];
        let permission = newPermissions.find(p => 
            p.permissionID === pid);
        if (permission === undefined)
            return;
        permission.granted = !permission.granted;
        setPermissions(newPermissions);
    }
    const save = () => {
        setError("");
        setMessage("");
        setLoading(true);
        SetPermissions(props.global, role, permissions).then((response) => {
            setLoading(false);
            if (!response.success)
            {
                setError(response.message);
                return
            }
            setMessage("Permissions Saved.");
        }).catch(() => {
            setLoading(false);
            setError("An error occurred.  Please try again later.");
        })
    }
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                <select className="select" value={role}
                    onChange={(e) => setRole(e.target.value)}>
                    <option value={-1}>-- Select a Role --</option>
                    {roles.map(r => 
                        <option key={r.roleID} value={r.roleID}>
                            {r.name}
                        </option>    
                    )}
                </select>
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Permission Name</th>
                        <th></th>
                    </tr>
                    {permissions.length === 0 && (
                        <tr>
                            <td colSpan="2"><i>Select a Role</i></td>
                        </tr>
                    )}
                    {permissions.map(p =>
                        <tr key={p.permissionID}>
                            <td>{p.name}</td>
                            <td>
                                <Link to="#" className="checkbox" 
                                    onClick={() => changePermission(p.permissionID)}>
                                    <i className={p.granted ? "fa-solid fa-square-check fa-2x" :
                                        "fa-solid fa-square fa-2x"}></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {permissions.length > 0 && (
                <div className="button-container">
                    <Link to="#" className="button"
                        onClick={save}>
                        Save Changes
                    </Link>
                </div>
            )}
            <div className="error">{error}</div>
            <div className="success">{message}</div>
        </div>
    )
}
export default RolePermissions;