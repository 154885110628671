import APIRequest from './APIRequest.js';

export const GetPhoneTypes = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetPhoneTypes',
        method: 'get'
    })
}

export const GetInstallStatuses = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetInstallStatuses',
        method: 'get'
    })
}

export const GetTimeUnits = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetTimeUnits',
        method: 'get'
    })
}

export const GetUploadFlags = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetUploadFlags',
        method: 'get'
    })
}

export const GetTaskStatuses = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetTaskStatuses',
        method: 'get'
    })
}

export const GetNoteStatuses = (global) => {
    return APIRequest({
        global: global,
        url: 'Enum/GetNoteStatuses',
        method: 'get'
    })
}