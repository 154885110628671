import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetTaskList } from '../API/InstallationAPI';
import Loader from './Loader.js';

// need to create Task.js and add to AuthorizedRoutes.js
const TaskList = (props) => {
    //const [status, setStatus] = useState(-1);
    const [taskInfos, setTaskInfos] = useState([]);
    //const [taskStatuses, setTaskStatuses] = useState([]);
    const [canAdd, setCanAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetTaskList(props.global, props.instId).then(response => {
            setLoading(false);
            if (response.success) {
                setTaskInfos(response.tasks);
                //setTaskStatuses(response.taskStatuses);
                setCanAdd(response.canAddEdit);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, props.instId])
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                {canAdd && (
                    <Link to={`/Installations/${props.instId}/Tasks/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Task
                    </Link>
                )}
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Task Name</th>
                        <th>Status</th>
                    </tr>
                    {taskInfos.length === 0 && (
                        <tr>
                            <td colSpan="2"><i>No Tasks Found.</i></td>
                        </tr>
                    )}
                    {taskInfos.map(t =>
                        <tr key={t.taskID}>
                            <td>
                                <Link to={`/Installations/${props.instId}/Tasks/${t.taskID}`}>
                                    {t.taskName}
                                </Link>
                            </td>
                            <td>
                                {t.statusTypeName}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default TaskList;