import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Loader from '../Loader.js';
import { GetAccountInstall } from '../../API/AccountAPI.js';

const AccountInstallation = (props) => {
    const [loading, setLoading] = useState(true);
    const [installID, setInstallID] = useState(-1);
    const [startDate, setStartDate] = useState("");
    const [expectedCompletionDate, setExpectedCompletionDate] = useState("");
    const [statusName, setStatusName] = useState("");
    let { id } = useParams();
    useEffect(() => {
        setLoading(true);
        GetAccountInstall(props.global, id).then(response => {
            setLoading(false);
            setInstallID(response.installID);
            setStartDate(response.startDate);
            setExpectedCompletionDate(response.expectedCompletionDate);
            setStatusName(response.statusName);
        }).catch(() => {
            setLoading(false);
        })
    }, [])
    return (
        
        <div className="widget-container account-installation-container">
            {loading && <Loader />}
            {installID === -1 ? (
                <div>
                    <p>
                        <i>No installation records available.</i>
                    </p>
                    <Link to={`/Installations/new/${id}`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> New Installation
                    </Link>
                </div>
            ) : (
                <div className="pools-table-container">
                    <table className="pools-table">
                        <tbody>
                            <tr>
                                <th>Start Date</th>
                                <th>Expected Completion Date</th>
                                <th>Status</th>
                            </tr>
                            <tr>
                                <td>
                                    <Link to={`/Installations/${installID}`}>
                                        {startDate}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/Installations/${installID}`}>
                                        {expectedCompletionDate}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/Installations/${installID}`}>
                                        {statusName}
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
export default AccountInstallation;