import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetNoteList } from '../API/InstallationAPI';
import Loader from './Loader.js';

const NoteList = (props) => {
    const [canSeeInternal, setCanSeeInternal] = useState(false);
    const [canSeeInactive, setCanSeeInactive] = useState(false);
    const [flag, setFlag] = useState(-1);
    const [flags, setFlags] = useState([]);
    const [showInactive, setShowInactive] = useState(false);
    const [notes, setNotes] = useState([]);
    //const [canAdd, setCanAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetNoteList(props.global, showInactive, props.instId, props.tskID, props.servID, flag).then(response => {
            setLoading(false);
            if (response.success) {
                setNotes(response.notes);
                setFlags(response.flags);
                setCanSeeInactive(response.canSeeInactive);
                setCanSeeInternal(response.canSeeInternal);
                //setCanAdd(response.canAddEdit);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, props.instId, props.tskID, props.servID, showInactive, flag])
    //useEffect(() => {
    //    console.log("checkbox: " + showInactive);
    //}, [showInactive])
    const handleFilter = (e) => {
        setFlag(e.target.value);
    }
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                {(props.instId !== -1 && props.tskID === -1) && (
                    <Link to={`/Installations/${props.instId}/Notes/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Note
                    </Link>
                )}
                {(props.instId !== -1 && props.tskID !== -1) && (
                    <Link to={`/Installations/${props.instId}/Tasks/${props.tskID}/Notes/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Note
                    </Link>
                )}
                {(props.servID !== null && props.servID !== -1) && (
                    <Link to={`/Services/${props.servID}/Notes/new`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add New Note
                    </Link>
                )}
                {canSeeInternal === true && (
                    <div className="dropdown-area">
                        <select value={flag} className="select flag-select"
                            onChange={(e) => handleFilter(e)}>
                            <option value={-1}>All Flags</option>
                            {flags.map(f =>
                                <option key={"f-" + f.flagID} value={f.flagID}>
                                    {f.flagName}
                                </option>
                            )}
                        </select>
                    </div>
                )}
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Description</th>
                        <th>User</th>
                        <th>Date</th>
                        {canSeeInternal === true && (
                            <th>Flag</th>
                        )}
                    </tr>
                    {notes.length === 0 && (
                        <tr>
                            <td colSpan="4"><i>No Notes Found.</i></td>
                        </tr>
                    )}
                    {notes.map(n =>
                        <tr key={"n-" + n.noteID}>
                            <td>
                                {((props.instId !== -1 && props.instId !== undefined) && props.tskID === -1) && (
                                    <Link to={`/Installations/${props.instId}/Notes/${n.noteID}`}>
                                        {n.noteDescript}
                                    </Link>
                                )}
                                {((props.instId !== -1 && props.instId !== undefined) && props.tskID !== -1) && (
                                    <Link to={`/Installations/${props.instId}/Tasks/${props.tskID}/Notes/${n.noteID}`}>
                                        {n.noteDescript}
                                    </Link>
                                )}
                                {(props.servID !== -1 && props.servID !== undefined) && (
                                    <Link to={`/Services/${props.servID}/Notes/${n.noteID}`}>
                                        {n.noteDescript}
                                    </Link>
                                )}
                            </td>
                            <td>
                                {n.userFName} {n.userLName}
                            </td>
                            <td>
                                {n.noteTime_fmt}
                            </td>
                            {canSeeInternal === true && (
                                <td>
                                    {n.flagName}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default NoteList;