import APIRequest from './APIRequest.js';

export const SetDeviceTokens = (global, deviceToken, isAndroidDevice) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/SetDeviceTokens',
        method: 'post',
        body: {
            "deviceToken": deviceToken,
            "isAndroidDevice": isAndroidDevice,
        }
    })
}

export const GetUpcomingInstalls = (global) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/GetUpcomingInstalls',
        method: 'post',
    })
}

export const GetTasks = (global) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/GetTasks',
        method: 'post',
    })
}

export const GetUpcomingServices = (global) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/GetUpcomingServices',
        method: 'post',
    })
}

export const GetPastDueInstalls = (global) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/GetPastDueInstalls',
        method: 'post',
    })
}

export const GetPastDueServices = (global) => {
    return APIRequest({
        global: global,
        url: 'Dashboard/GetPastDueServices',
        method: 'post',
    })
}