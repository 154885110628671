import APIRequest from './APIRequest.js';

export const GetAccountList = (global, pageNum, pageSize, 
    ascending, sortExpression, searchExpr) => {
        return APIRequest({
            global: global,
            url: 'Account/GetAccountList',
            method: 'post',
            body: {
                "pageNum": pageNum,
                "pageSize": pageSize,
                "ascending": ascending,
                "sortExpression": sortExpression,
                "searchExpr": searchExpr
            }
        });
}
export const GetAccount = (global, accountID) => {
    return APIRequest({
        global: global,
        url: 'Account/GetAccount',
        method: 'post',
        body: {
            "accountID": accountID
        }
    })
}
export const GetAccountInstall = (global, accountID) => {
    return APIRequest({
        global: global,
        url: 'Account/GetAccountInstall',
        method: 'post',
        body: {
            "accountID": accountID
        }
    })
}
export const GetAccountServices = (global, acctID) => {
    return APIRequest({
        global: global,
        url: 'Account/GetAccountServices',
        method: 'post',
        body: {
            "accountID": acctID
        }
    })
}
export const GetAccountHolders = (global, accountID) => {
    return APIRequest({
        global: global,
        url: 'Account/GetAccountHolders',
        method: 'post',
        body: {
            "accountID": accountID
        }
    })
}
export const GetAccountHolder = (global, userID) => {
    return APIRequest({
        global: global,
        url: 'Account/GetAccountHolder',
        method: 'post',
        body: {
            "userID": userID
        }
    })
}
export const SaveAccountHolder = (global, userID,
    accountId, firstName, lastName, primaryPhone,
    primaryPhoneTypeID, secondaryPhone,
    secondaryPhoneTypeID, email) => {
    return APIRequest({
        global: global,
        url: 'Account/SaveAccountHolder',
        method: 'post',
        body: {
            "accountID": accountId,
            "userInfo": {
                "userID": userID,
                "firstName": firstName,
                "lastName": lastName,
                "primaryPhone": primaryPhone,
                "primaryPhoneTypeID": primaryPhoneTypeID,
                "secondaryPhone": secondaryPhone,
                "secondaryPhoneTypeID": secondaryPhoneTypeID,
                "email": email
            }
        }
    })
}
export const CreateAccount = (global, address1, address2,
    city, state, zip, firstName, lastName, primaryPhone,
    primaryPhoneTypeID, secondaryPhone,
    secondaryPhoneTypeID, email) => {
        return APIRequest({
            global: global,
            url: 'Account/CreateAccount',
            method: 'post',
            body: {
                "accountInfo": {
                    "address1": address1,
                    "address2": address2,
                    "city": city,
                    "state": state,
                    "zip": zip,
                    "accountHolders": [
                        {
                            "firstName": firstName,
                            "lastName": lastName,
                            "primaryPhone": primaryPhone,
                            "primaryPhoneTypeID": primaryPhoneTypeID,
                            "secondaryPhone": secondaryPhone,
                            "secondaryPhoneTypeID": secondaryPhoneTypeID,
                            "email": email
                        }
                    ]
                }
            }
        })
}