import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {GetAccountList} from '../API/AccountAPI.js';
import Loader from './Loader.js';
import Pager from './Pager.js';

const Accounts = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [searchText, setSearchText] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [pageNum, setPageNum] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [ascending, setAscending] = useState(true);
    const [sortExpression, setSortExpression] = useState("");
    const [searchExpr, setSearchExpr] = useState("");
    useEffect(() => {
        setLoading(true);
        GetAccountList(props.global, pageNum, pageSize, 
            ascending, sortExpression, searchExpr).then(response => {
                setLoading(false);
                if (!response.success)
                {
                    setError(response.message);
                    return;
                }
                setError("");
                setAccounts(response.accounts);
                setTotalResults(response.totalResults);
        }).catch(() => {
            setLoading(false);
            setError("Something went wrong.  Please try again later.");
        })
    }, [props.global, pageNum, pageSize, ascending, 
        sortExpression, searchExpr]);
    const changeSort = (newSort) => {
        if (newSort === sortExpression)
        {
            setAscending(!ascending);
            return;
        }
        setSortExpression(newSort);
    }
    const submitSearch = (e) => {
        e.preventDefault();
        runSearch();
    }
    const runSearch = () => {
        setSearchExpr(searchText);
    }
    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Accounts</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <div className="error">{error}</div>
                <div className="pools-table-container">
                    <div className="button-container">
                        <Link to="/Accounts/new" className="button">
                            <i className="fa-solid fa-circle-plus"></i> Add New Account
                        </Link>
                        <form className="search-area" onSubmit={(e) => submitSearch(e)}>
                            <input type="search" className="text-field" 
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)} />
                            <Link to="#" className="button" 
                                onClick={() => runSearch()}>
                                Search
                            </Link>
                            <input type="submit" value="" className="form-submit" />
                        </form>
                    </div>
                    <table className="pools-table">
                        <tbody>
                            <tr>
                                <th>
                                    <Link to="#" onClick={() => changeSort("name")}>
                                        Customer Name
                                    </Link>
                                </th>
                                <th>
                                    <Link to="#" onClick={() => changeSort("email")}>
                                        Customer Email
                                    </Link>
                                </th>
                                <th>
                                    <Link to="#" onClick={() => changeSort("address")}>
                                        Address
                                    </Link>
                                </th>
                            </tr>
                            {accounts.length === 0 && (
                                <tr>
                                    <td colSpan="3">
                                        <i>No Accounts Found.</i>
                                    </td>
                                </tr>
                            )}
                            {accounts.map(acct =>
                                <tr key={acct.accountID}>
                                    <td>
                                        {acct.accountHolders.map((usr, idx) =>
                                            <span key={usr.userID}>
                                                <Link to={`/Accounts/${acct.accountID}`}>
                                                    {usr.lastName + ", " + usr.firstName}
                                                </Link>
                                                {idx !== acct.accountHolders.length - 1 && (
                                                    <hr />
                                                )}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {acct.accountHolders.map((usr, idx) => 
                                            <span key={usr.userID}>
                                                <a href={"mailto:" + usr.email}>
                                                    {usr.email}
                                                </a>
                                                {idx !== acct.accountHolders.length - 1 && (
                                                    <hr />
                                                )}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {acct.address1}<br />
                                        {acct.address2}
                                        {acct.address2.trim() !== "" && <br />}
                                        {acct.city}, {acct.state} {acct.zip}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="pager">
                        <Pager totalResults={totalResults} pageSize={pageSize}
                            pageNum={pageNum} setPageNum={setPageNum} />
                        <select className="select" value={pageSize}
                            onChange={(e) => {
                                setPageNum(1); setPageSize(e.target.value)
                            }}>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accounts