import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader.js';
import { GetInstallList } from '../API/InstallationAPI.js';
import Pager from './Pager.js';

const Installations = (props) => {
    const [loading, setLoading] = useState(false);
    const [showSomeStatuses, setShowStatuses] = useState(-1);
    const [canAdd, setCanAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [installsInfo, setInstallsInfo] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [pageNum, setPageNum] = useState(1);
    useEffect(() => {
        setLoading(true);
        GetInstallList(props.global, 
            parseInt(showSomeStatuses) === -1 ? null : showSomeStatuses, 
            pageNum, pageSize).then(response => {
            setLoading(false);
            if (response.success) {
                setStatuses(response.installStatuses);
                setInstallsInfo(response.installsInfo);
                setTotalResults(response.totalResults);
                setCanAdd(response.canAddEdit);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, showSomeStatuses, pageNum, pageSize]);

    const handleFilter = (e) => {
        setShowStatuses(e.target.value);
    }

    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Installations</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <div className="button-container">
                    {canAdd && (
                        <Link to="/Installations/new" className="button">
                            <i className="fa-solid fa-circle-plus"></i> Add New Installation
                        </Link>
                    )}
                    <div className="dropdown-area">
                        <select value={showSomeStatuses} className="select status-select"
                            onChange={(e) => handleFilter(e)}>
                            <option value={-1}>All Statuses</option>
                            {statuses.map(s =>
                                <option key={"s-" + s.statusID} value={s.statusID}>
                                    {s.statusName}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="pools-table-container">
                    <table className="pools-table">
                        <thead>
                            <tr>
                                <th>Account Address</th>
                                <th>Estimated Start Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {installsInfo.length === 0 && (
                                <tr>
                                    <td colSpan="4">
                                        <i>No records found.</i>
                                    </td>
                                </tr>
                            )}
                            {installsInfo.map(install => (
                                        <tr key={install.installID}>
                                            <td>
                                                <Link to={`/Installations/${install.installID}`}>
                                                    {install.custAddress1}<br />
                                                    {install.custAddress2}
                                                    {install.custAddress2?.trim() !== "" && <br />}
                                                    {install.custCity}, {install.custState} {install.custZip}
                                                </Link>
                                            </td>
                                            <td>
                                                {install.startDate_fmt}
                                            </td>
                                            <td>
                                                {install.statusTypeName}
                                            </td>
                                        </tr>
                                )
                            )}
                            {/*{installsInfo.map(install =>*/}
                            {/*    <tr key={install.installID}>*/}
                            {/*        <td>*/}
                            {/*            <Link to={`/Installations/${install.installID}`}>*/}
                            {/*                {install.custAddress1}<br />*/}
                            {/*                {install.custAddress2}*/}
                            {/*                {install.custAddress2?.trim() !== "" && <br />}*/}
                            {/*                {install.custCity}, {install.custState} {install.custZip}*/}
                            {/*            </Link>*/}
                            {/*        </td>*/}
                            {/*        <td>*/}
                            {/*            {install.startDate_fmt}*/}
                            {/*        </td>*/}
                            {/*        <td>*/}
                            {/*            {install.statusTypeName}*/}
                            {/*        </td>*/}
                            {/*    </tr>*/}
                            {/*)}*/}
                        </tbody>
                    </table>
                    <div className="pager">
                        <Pager totalResults={totalResults} pageSize={pageSize}
                            pageNum={pageNum} setPageNum={setPageNum} />
                        <select className="select" value={pageSize}
                            onChange={(e) => {
                                setPageNum(1); setPageSize(e.target.value)
                            }}>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Installations