import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {GetRoleList} from '../API/AccessAPI';
import Loader from './Loader.js';

const RoleList = (props) => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetRoleList(props.global).then(response => {
            setRoles(response.roles);
            setLoading(false);
        })
    }, [props.global])
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="button-container">
                <Link to="/Roles/new" className="button">
                    <i className="fa-solid fa-circle-plus"></i> Add New Role
                </Link>
            </div>
            <table className="pools-table">
                <tbody>
                    <tr>
                        <th>Role Name</th>
                    </tr>
                    {roles.length === 0 && (
                        <tr>
                            <td><i>No Roles Found.</i></td>
                        </tr>
                    )}
                    {roles.map(r => 
                        <tr key={r.roleID}>
                            <td>
                                <Link to={`/Roles/${r.roleID}`}>
                                    {r.name}
                                </Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default RoleList;