import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetInstall, CreateInstall, SaveInstall } from '../API/InstallationAPI';
import TaskList from './TaskList.js';
import NoteList from './NoteList.js';
import AttachList from './AttachList.js';
import Loader from './Loader.js';
//import dateFormat from "dateformat";
import Select from "react-select";
// import NotifServce from '../NotifService'

const Installation = (props) => {
    let { id } = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [canEdit, setCanEdit] = useState(false);
    const [canSeeEmps, setCanSeeEmps] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusTypes, setStatusTypes] = useState([]);
    const [displayName, setDisplayName] = useState("Loading...");
    const [accountId, setAccountId] = useState(-1);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [startDate2, setStartDate2] = useState();
    const [startDateTime, setStartDateTime] = useState();
    const [startDate_fmt, setStartDateFmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [expectCompDate, setExpectCompDate] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [expectCompDate2, setExpectCompDate2] = useState();
    const [expectCompDateTime, setExpectCompDateTime] = useState();
    const [expectCompDate_fmt, setExpectCompDate_fmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [completeDate, setCompleteDate] = useState(null);
    const [completeDate2, setCompleteDate2] = useState();
    const [completeDateTime, setCompleteDateTime] = useState(null);
    const [completeDate_fmt, setCompleteDate_fmt] = useState(null);
    const [statusType, setStatusType] = useState(0);
    const [statusTypeName, setStatusTypeName] = useState("New");
    const [custFName, setCustFname] = useState("");     // current cust
    const [custLName, setCustLname] = useState("");     // current cust
    const [empNames, setEmpNames] = useState([]);   // current assigned emps
    const [anEmpName, setAnEmpName] = useState(null);
    const [emps, setEmps] = useState([]);   // list of all emps
    const [custs, setCusts] = useState([]);     // list of all custs
    // need ones for InstallUserID, InstallID, UserID from InstallUser table?
    useEffect(() => {
        setLoading(true);
        GetInstall(props.global, id).then((response) => {
            if (!response.success) {
                history.push('/Installations');
                setLoading(false);
                return;
            }
            //console.log(response);
            setStatusTypes(response.installStatuses);
            setCanEdit(response.canAddEdit);
            let installInfo = response.installInfo;
            setDisplayName("New Installation");
            setLoading(false);
            setEmps(response.emps);
            setCusts(response.custs);
            setCanSeeEmps(response.canSeeEmps)
            if (id === -1) {
                let acctId = response.custs[0];
                setAccountId(acctId.accountID);
                setMode("create");  // edit
                return;
            }
            setDisplayName("Installation")
            //console.log(installInfo);
            setAccountId(installInfo.accountID)
            setStartDate(installInfo.startDate)
            setStartDate2(installInfo.startDate2)
            setStartDateTime(installInfo.startDate + ' ' + installInfo.startDate2)
            setStartDateFmt(installInfo.startDate_fmt)
            setExpectCompDate(installInfo.expectCompleteDate)
            setExpectCompDate2(installInfo.expectCompleteDate2)
            setExpectCompDateTime(installInfo.expectCompleteDate + ' ' + installInfo.expectCompleteDate2)
            setExpectCompDate_fmt(installInfo.expectCompleteDate_fmt)
            setCompleteDate(installInfo.completionDate)
            setCompleteDate2(installInfo.completionDate2)
            setCompleteDateTime(installInfo.completionDate + ' ' + installInfo.completionDate2)
            setCompleteDate_fmt(installInfo.completionDate_fmt)
            setStatusType(installInfo.status)
            setStatusTypeName(installInfo.statusTypeName)
            setCustFname(installInfo.custFname)
            setCustLname(installInfo.custLname)
            setEmpNames(installInfo.empsNames.map(u => {
                return {
                    value: u.userID, label: u.lastName + ", " + u.firstName
                }
            }))
        })
    }, [props.global, id, history, mode, statusTypeName, setStatusType])
    var allEmps = emps.map(e => ({ value: e.userID, label: e.lastName + ", " + e.firstName }));
    const cancelClick = () => {
        if (id === -1) {
            history.push('/Installations');
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (startDateTime === "" || startDateTime === null || startDateTime === undefined) {
            setError("A start date is required."); return;
        }
        if (expectCompDateTime === "" || expectCompDateTime === null || expectCompDateTime === undefined) {
            setError("An expected completion date is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            SaveInstall(props.global, id, accountId, startDateTime, expectCompDateTime, completeDateTime, statusType, empNames.map(e => e.value)).then(response => {
                //console.log(response)
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (id === -1) {
                    history.push(`/Installations/${response.installID}`);
                }
                setMode("view");
            })
        }
        else {
            SaveInstall(props.global, id, accountId, startDateTime, expectCompDateTime, completeDateTime, statusType, null).then(response => {
                //console.log(response)
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (id === -1) {
                    history.push(`/Installations/${response.installID}`);
                }
                setMode("view");
            })
        }
    }
    const submit = () => {
        setError("");
        if (startDateTime === "" || startDateTime === null || startDateTime === undefined) {
            setError("A start date is required."); return;
        }
        if (expectCompDateTime === "" || expectCompDateTime === null || expectCompDateTime === undefined) {
            setError("An expected completion date is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            CreateInstall(props.global, id, accountId, startDateTime, expectCompDateTime, completeDateTime, statusType, empNames.map(e => e.value)).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (id === -1) {
                    history.push(`/Installations/${response.install.installID}`);
                }
                // NotifServce.notif("New Installation Created with Assigned Employees");
                setMode("view");
            })
        }
        else {
            CreateInstall(props.global, id, accountId, startDateTime, expectCompDateTime, completeDateTime, statusType, null).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (id === -1) {
                    history.push(`/Installations/${response.install.installID}`);
                }
                // NotifServce.notif("New Installation Created");
                setMode("view");
            })
        }
    }
    const handleEmpSelect = (e) => {
        //console.log(e);
        setEmpNames(e);
        setAnEmpName(e);
    }
    useEffect(() => {
        let hash = window.location.hash.match(/new\/([0-9]*)$/)
        if (hash)
        {
            let cust = custs.find(c => 
                c.accountID === parseInt(hash[1])
            );
            if (cust !== undefined)
            {
                setAccountId(cust.accountID);
            }
        }
    }, [custs, accountId])
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
        setStartDateTime(e.target.value + ' ' + startDate2);
    }
    const handleStartTime = (e) => {
        setStartDate2(e.target.value);
        setStartDateTime(startDate + ' ' + e.target.value);
    }
    const handleExpectCompDate = (e) => {
        setExpectCompDate(e.target.value);
        setExpectCompDateTime(e.target.value + ' ' + expectCompDate2);
    }
    const handleExpectCompTime = (e) => {
        setExpectCompDate2(e.target.value);
        setExpectCompDateTime(expectCompDate + ' ' + e.target.value);
    }
    const handleCompDate = (e) => {
        setCompleteDate(e.target.value);
        setCompleteDateTime(e.target.value + ' ' + completeDate2);
    }
    const handleCompTime = (e) => {
        setCompleteDate2(e.target.value);
        setCompleteDateTime(completeDate + ' ' + e.target.value);
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Installations" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e)}>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Account Name</th>
                                {/* do something here for adding customer to install */}
                                <td>
                                    {mode !== "create" &&
                                        (<Link to={`/Accounts/${accountId}`}>
                                            {custFName} {custLName}
                                        </Link>)
                                    }
                                    {mode === "create" && (
                                        <div className="account-input-container">
                                            <select className="select acct-select"
                                                onChange={(e) => setAccountId(e.target.value)} value={accountId === null ? -1 : accountId}>
                                                {custs.map(c =>
                                                    <option key={"c-" + c.userID} value={c.accountID}>
                                                        {c.lastName + ", " + c.firstName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Estimated Start Date</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="date" className="text-field" value={startDate}
                                            onChange={(e) => handleStartDate(e)} required />
                                    ) : startDate_fmt}
                                    {mode !== "view" && (
                                        <input type="time" className="text-field" value={startDate2}
                                            onChange={(e) => handleStartTime(e)} required />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Expected Completion Date</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="date" className="text-field" value={expectCompDate === null ? "" : expectCompDate}
                                            onChange={(e) => handleExpectCompDate(e)} required />
                                    ) : expectCompDate_fmt}
                                    {mode !== "view" && (
                                        <input type="time" className="text-field" value={expectCompDate2 === null ? "" : expectCompDate2}
                                            onChange={(e) => handleExpectCompTime(e)} required />
                                    )}
                                </td>
                            </tr>
                            {mode !== "create" && (
                                <tr>
                                    <th>Completion Date</th>
                                    <td>
                                        {(completeDate === null && mode === "view") && <i>No completion date.</i>}
                                        {(completeDate !== null && mode === "view" && completeDate_fmt)}
                                        {mode !== "view" && (
                                            <input type="date" className="text-field" value={completeDate}
                                                onChange={(e) => handleCompDate(e)} />
                                        )}
                                        {mode !== "view" && (
                                            <input type="time" className="text-field" value={completeDate2}
                                                onChange={(e) => handleCompTime(e)} />
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th>Status</th>
                                <td>
                                    {/* creating install auto sets status to new */}
                                    {mode === "edit" ? (
                                        <div className="status-input-container">
                                            <select value={statusType} className="select status-select"
                                                onChange={(e) => setStatusType(e.target.value)}>
                                                {statusTypes.map(s =>
                                                    <option key={"st-" + s.statusID} value={s.statusID}>
                                                        {s.statusName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        ) : statusTypeName}
                                </td>
                            </tr>
                            <tr>
                                {/* should be able to edit emps assigned */}
                                <th>Assigned Employee(s)</th>
                                {(empNames.length === 0 && mode === "view") && (
                                    <td>
                                        <i>No assigned employees.</i>
                                    </td>
                                )}
                                {(empNames.length !== 0 && mode === "view" && canSeeEmps === true) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                <Link to={`/Employees/${e.value}`}>
                                                    {e.label}
                                                </Link>
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {(empNames.length !== 0 && mode === "view" && canSeeEmps === false) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                {e.label}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {mode === "create" && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)" value={anEmpName === null ? "" : anEmpName} options={allEmps} onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                                {mode === "edit" && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)"
                                            value={empNames}
                                            options={allEmps}
                                            onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className="error">{error}</div>
                <div className="button-container">
                    {mode === "view" && canEdit && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {mode !== "view" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            {mode === "edit" && (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )}
                            {mode === "create" && (
                                <Link to="#" className="button"
                                onClick={submit}>
                                Submit
                                </Link>
                            )}
                        </>
                    )}
                </div>
                {mode === "view" && ( <hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Tasks</h2>
                )}
                {mode === "view" && (
                    <TaskList global={props.global} instId={id} />
                )}

                {mode === "view" && ( <hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Notes</h2>
                )}
                {mode === "view" &&  (
                    <NoteList global={props.global} instId={id} tskID={-1} servID={-1} />
                )}

                {mode === "view" && (<hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Attachments</h2>
                )}
                {mode === "view" && (
                    <AttachList global={props.global} instId={id} servID={-1} acctID={-1} noteID={-1} />
                )}
            </div>
        </div>
    )
}

export default Installation