import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetServiceCall, CreateServiceCall, SaveServiceCall } from '../API/ServiceCallAPI';
import NoteList from './NoteList.js';
import AttachList from './AttachList.js';
import Loader from './Loader.js';
//import dateFormat from "dateformat";
import Select from "react-select";

const Service = (props) => {
    let { id } = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [canEdit, setCanEdit] = useState(false);
    const [canSeeEmps, setCanSeeEmps] = useState(false);
    const [loading, setLoading] = useState(false);
    const [svcStatusTypes, setSvcStatusTypes] = useState([]);   // svc statuses
    const [svcTypes, setSvcTypes] = useState([]);   // svc types
    const [svcSchedules, setSvcSchedules] = useState([])  // svc schedule units
    const [displayName, setDisplayName] = useState("Loading...");
    const [svcDescript, setSvcDescript] = useState("");
    const [svcDate, setSvcDate] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [svcDate2, setSvcDate2] = useState();
    const [svcDateTime, setSvcDateTime] = useState();
    const [svcDate_fmt, setSvcDate_fmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [arrivalDate, setArrivalDate] = useState(null);
    const [arrivalDate2, setArrivalDate2] = useState();
    const [arrivalDateTime, setArrivalDateTime] = useState(null);
    const [arrivalDate_fmt, setArrivalDate_fmt] = useState(null);
    const [expectDepartDate, setExpectDepartDate] = useState(null);
    const [expectDepartDate2, setExpectDepartDate2] = useState();
    const [expectDepartDateTime, setExpectDepartDateTime] = useState(null);
    const [edd_fmt, setEDD_fmt] = useState(null);
    const [departDate, setDepartDate] = useState(null);
    const [departDate2, setDepartDate2] = useState();
    const [departDateTime, setDepartDateTime] = useState(null);
    const [departDate_fmt, setDepartDate_fmt] = useState(null);
    const [accountId, setAccountId] = useState(-1);
    const [svcType, setSvcType] = useState(0);
    const [svcTypeName, setSvctypeName] = useState("None");
    const [statusType, setStatusType] = useState(0);
    const [statusTypeName, setStatusTypeName] = useState("Scheduled");
    const [svcTempId, setSvcTempId] = useState(null);     // use for anything???
    const [waterClarity, setWaterClarity] = useState("");
    const [origPumpPressure, setOrigPumpPressure] = useState("");
    const [backWashed, setBackWashed] = useState(false);
    const [skimmerBsktsClean, setSkimmerBsktsClean] = useState(false);
    const [origCL, setOrigCL] = useState(null);
    const [origPH, setOrigPH] = useState(null);
    const [origTA, setOrigTA] = useState(null);
    const [origCA, setOrigCA] = useState(null);
    const [departPumpPressure, setDepartPumpPressure] = useState("");
    const [departCL, setDepartCL] = useState(null);
    const [departPH, setDepartPH] = useState(null);
    const [departTA, setDepartTA] = useState(null);
    const [departCA, setDepartCA] = useState(null);
    const [svcScheduleType, setSvcScheduleType] = useState(0);
    const [svcScheduleTypeName, setSvcScheduleTypeName] = useState("");
    const [userType, setUserType] = useState(0);
    const [custFName, setCustFname] = useState("");     // current cust
    const [custLName, setCustLname] = useState("");     // current cust
    const [empNames, setEmpNames] = useState([]);   // current assigned emps
    const [anEmpName, setAnEmpName] = useState(null);
    const [emps, setEmps] = useState([]);   // list of all emps
    const [custs, setCusts] = useState([]);     // list of all custs
    useEffect(() => {
        setLoading(true);
        GetServiceCall(props.global, id).then((response) => {
            if (!response.success) {
                history.push('/Services');
                setLoading(false);
                return;
            }
            setSvcStatusTypes(response.svcStatuses);
            setSvcTypes(response.svcTypes);
            setSvcSchedules(response.svcSchedules);
            setCanEdit(response.canAddEdit);
            setCanSeeEmps(response.canSeeEmps)
            setUserType(response.userType);
            let svcInfo = response.svcCallInfo;
            setDisplayName("New Service");
            setLoading(false);
            setEmps(response.emps);
            setCusts(response.custs);
            if (id === -1) {
                let acctId = response.custs[0];
                setAccountId(acctId.accountID);
                setMode("create");  // edit
                return;
            }
            setDisplayName("Service")
            setSvcDescript(svcInfo.svcDescript);
            setSvcDate(svcInfo.svcDate)
            setSvcDate2(svcInfo.svcDate2)
            setSvcDateTime(svcInfo.svcDate + ' ' + svcInfo.svcDate2)
            setSvcDate_fmt(svcInfo.svcDate_fmt)
            setArrivalDate(svcInfo.arrivalDate)
            setArrivalDate2(svcInfo.arrivalDate2)
            setArrivalDateTime(svcInfo.arrivalDate + ' ' + svcInfo.arrivalDate2)
            setArrivalDate_fmt(svcInfo.arrivalDate_fmt)
            setExpectDepartDate(svcInfo.expectDepartDate)
            setExpectDepartDate2(svcInfo.expectDepartDate2)
            setExpectDepartDateTime(svcInfo.expectDepartDate + ' ' + svcInfo.expectDepartDate2)
            setEDD_fmt(svcInfo.expectDepartDate_fmt)
            setDepartDate(svcInfo.departDate);
            setDepartDate2(svcInfo.departDate2)
            setDepartDateTime(svcInfo.departDate + ' ' + svcInfo.departDate2)
            setDepartDate_fmt(svcInfo.departDate_fmt)
            setAccountId(svcInfo.accountID)
            setSvcType(svcInfo.svcType);
            setSvctypeName(svcInfo.svcTypeName);
            setStatusType(svcInfo.svcStatus)
            setStatusTypeName(svcInfo.svcStatusName)
            setSvcTempId(svcInfo.svcTempID);
            setWaterClarity(svcInfo.waterClarity);
            setOrigPumpPressure(svcInfo.origPumpPressure);
            setBackWashed(svcInfo.backWashed);
            setSkimmerBsktsClean(svcInfo.skimmerBsktsClean)
            setOrigCL(svcInfo.origCL);
            setOrigPH(svcInfo.origPH);
            setOrigTA(svcInfo.origTA);
            setOrigCA(svcInfo.origCA)
            setDepartPumpPressure(svcInfo.departPumpPressure)
            setDepartCL(svcInfo.departCL)
            setDepartPH(svcInfo.departPH)
            setDepartTA(svcInfo.departTA)
            setDepartCA(svcInfo.departCA)
            setCustFname(svcInfo.custFName)
            setCustLname(svcInfo.custLName)
            setSvcScheduleType(svcInfo.svcSchedule);
            setSvcScheduleTypeName(svcInfo.svcScheduleName)
            setEmpNames(svcInfo.empsNames.map(u => {
                return {
                    value: u.userID, label: u.lastName + ", " + u.firstName
                }
            }))
        })
    }, [props.global, id, history, mode, statusTypeName, setStatusType])
    var allEmps = emps.map(e => ({ value: e.userID, label: e.lastName + ", " + e.firstName }));
    const cancelClick = () => {
        if (id === -1) {
            history.push('/Services');
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (svcDateTime === "" || svcDateTime === null || svcDateTime === undefined) {
            setError("A service date is required."); return;
        }
        if (svcDescript.trim() === "") {
            setError("Description/notes is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            SaveServiceCall(props.global, id, svcDescript, svcDateTime, arrivalDateTime, expectDepartDateTime, departDateTime, accountId, svcType, statusType, svcTempId,
                waterClarity, origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA,
                departPumpPressure, departCL, departPH, departTA, departCA, svcScheduleType, empNames.map(e => e.value)).then(response => {
                    //console.log(response)
                    setLoading(false);
                    if (!response.success) {
                        setError(response.message);
                        return;
                    }
                    if (id === -1) {
                        history.push(`/Services/${response.svcCallID}`);
                    }
                    setMode("view");
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
        }
        else {
            SaveServiceCall(props.global, id, svcDescript, svcDateTime, arrivalDateTime, expectDepartDateTime, departDateTime, accountId, svcType, statusType, svcTempId,
                waterClarity, origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA,
                departPumpPressure, departCL, departPH, departTA, departCA, svcScheduleType, null).then(response => {
                    //console.log(response)
                    setLoading(false);
                    if (!response.success) {
                        setError(response.message);
                        return;
                    }
                    if (id === -1) {
                        history.push(`/Services/${response.svcCallID}`);
                    }
                    setMode("view");
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
        }
    }
    const submit = () => {
        setError("");
        if (svcDateTime === "" || svcDateTime === null || svcDateTime === undefined) {
            setError("A service date is required."); return;
        }
        if (svcDescript.trim() === "") {
            setError("Description/notes is required."); return;
        }
        setLoading(true);
        if (empNames !== null || empNames !== "" || empNames.length !== 0) {
            CreateServiceCall(props.global, id, svcDescript, svcDateTime, arrivalDateTime, expectDepartDateTime, departDateTime, accountId, svcType, statusType, svcTempId,
                waterClarity, origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA,
                departPumpPressure, departCL, departPH, departTA, departCA, svcScheduleType, empNames.map(e => e.value)).then(response => {
                    setLoading(false);
                    if (!response.success) {
                        setError(response.message);
                        return;
                    }
                    if (id === -1) {
                        history.push(`/Services/${response.svcCall.serviceID}`);
                    }
                    setMode("view");
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
        }
        else {
            CreateServiceCall(props.global, id, svcDescript, svcDateTime, arrivalDateTime, expectDepartDateTime, departDateTime, accountId, svcType, statusType, svcTempId,
                waterClarity, origPumpPressure, backWashed, skimmerBsktsClean, origCL, origPH, origTA, origCA,
                departPumpPressure, departCL, departPH, departTA, departCA, svcScheduleType, null).then(response => {
                    setLoading(false);
                    if (!response.success) {
                        setError(response.message);
                        return;
                    }
                    if (id === -1) {
                        history.push(`/Services/${response.svcCall.serviceID}`);
                    }
                    setMode("view");
            }).catch((e) => {
                console.log(e);
                setLoading(false);
            });
        }
    }
    const handleEmpSelect = (e) => {
        //console.log(e);
        setEmpNames(e);
        setAnEmpName(e);
    }
    useEffect(() => {
        let hash = window.location.hash.match(/new\/([0-9]*)$/)
        if (hash)
        {
            let cust = custs.find(c => 
                c.accountID === parseInt(hash[1])
            );
            if (cust !== undefined)
            {
                setAccountId(cust.accountID);
            }
        }
    }, [custs, accountId])
    const handleSvcDateSelect = (e) => {
        setSvcDate(e.target.value);
        setSvcDateTime(e.target.value + ' ' + svcDate2);
    }
    const handleSvcTimeSelect = (e) => {
        setSvcDate2(e.target.value);
        setSvcDateTime(svcDate + ' ' + e.target.value);
    }
    const handleArrivalDateSelect = (e) => {
        setArrivalDate(e.target.value);
        setArrivalDateTime(e.target.value + ' ' + arrivalDate2);
    }
    const handleArrivalTimeSelect = (e) => {
        setArrivalDate2(e.target.value);
        setArrivalDateTime(arrivalDate + ' ' + e.target.value);
    }
    const handleExpectDepartDateSelect = (e) => {
        setExpectDepartDate(e.target.value);
        setExpectDepartDateTime(e.target.value + ' ' + expectDepartDate2);
    }
    const handleExpectDepartTimeSelect = (e) => {
        setExpectDepartDate2(e.target.value);
        setExpectDepartDateTime(expectDepartDate + ' ' + e.target.value);
    }
    const handleDepartDateSelect = (e) => {
        setDepartDate(e.target.value);
        setDepartDateTime(e.target.value + ' ' + departDate2);
    }
    const handleDepartTimeSelect = (e) => {
        setDepartDate2(e.target.value);
        setDepartDateTime(departDate + ' ' + e.target.value);
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Services" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e)}>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Account Name</th>
                                <td>
                                    {mode !== "create" &&
                                        (<Link to={`/Accounts/${accountId}`}>
                                            {custFName} {custLName}
                                        </Link>)
                                    }
                                    {mode === "create" && (
                                        <div className="account-input-container">
                                            <select className="select acct-select"
                                                onChange={(e) => setAccountId(e.target.value)} defaultValue={accountId === null ? -1 : accountId}>
                                                {custs.map(c =>
                                                    <option key={"c-" + c.userID} value={c.accountID}>
                                                        {c.lastName + ", " + c.firstName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Service Date</th>
                                <td>
                                    {mode !== "view" ? (
                                        <input type="date" className="text-field" value={svcDate}
                                            onChange={(e) => handleSvcDateSelect(e)} required />
                                    ) : svcDate_fmt}
                                    {mode !== "view" && (
                                        <input type="time" className="text-field" value={svcDate2}
                                            onChange={(e) => handleSvcTimeSelect(e)} required />
                                    )}
                                </td>
                            </tr>
                            {mode !== "create" && (
                                <tr>
                                    <th>Arrival Time</th>
                                    <td>
                                        {(arrivalDate === null && mode === "view") && <i>No arrival time.</i>}
                                        {(arrivalDate !== null && mode === "view" && arrivalDate_fmt)}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="date" className="text-field" value={arrivalDate}
                                                onChange={(e) => handleArrivalDateSelect(e)} />
                                        )}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="time" className="text-field" value={arrivalDate2}
                                                onChange={(e) => handleArrivalTimeSelect(e)} />
                                        )}
                                    </td>
                                </tr>
                            )}
                            {mode !== "create" && (
                                <tr>
                                    <th>Expected Departure Time</th>
                                    <td>
                                        {(expectDepartDate === null && mode === "view") && <i>No expected departure time.</i>}
                                        {(expectDepartDate !== null && mode === "view" && edd_fmt)}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="date" className="text-field" value={expectDepartDate}
                                                onChange={(e) => handleExpectDepartDateSelect(e)} />
                                        )}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="time" className="text-field" value={expectDepartDate2}
                                                onChange={(e) => handleExpectDepartTimeSelect(e)} />
                                        )}
                                    </td>
                                </tr>
                            )}
                            {mode !== "create" && (
                                <tr>
                                    <th>Departure Time</th>
                                    <td>
                                        {(departDate === null && mode === "view") && <i>No departure time.</i>}
                                        {(departDate !== null && mode === "view" && departDate_fmt)}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="date" className="text-field" value={departDate}
                                                onChange={(e) => handleDepartDateSelect(e)} />
                                        )}
                                        {(mode !== "view" && userType === 1) && (
                                            <input type="time" className="text-field" value={departDate2}
                                                onChange={(e) => handleDepartTimeSelect(e)} />
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th>Description/Notes</th>
                                <td>
                                    {(mode !== "view" && userType === 1) ? (
                                        <textarea className="text-field large-text-field" value={svcDescript} onChange={(e) => setSvcDescript(e.target.value)} required />
                                    ) : svcDescript}
                                </td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>
                                    {(mode === "create" && userType === 1) ? (
                                        <div className="status-input-container">
                                            <select value={svcType} className="select status-select"
                                                onChange={(e) => setSvcType(e.target.value)}>
                                                {svcTypes.map(s =>
                                                    <option key={"st-" + s.serviceTypeID} value={s.serviceTypeID}>
                                                        {s.serviceTypeName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    ) : svcTypeName}
                                </td>
                            </tr>
                            <tr>
                                <th>Occurence</th>
                                <td>
                                    {(mode !== "view" && userType === 1) ? (
                                        <div className="status-input-container">
                                            <select value={svcScheduleType} className="select status-select"
                                                onChange={(e) => setSvcScheduleType(e.target.value)}>
                                                {svcSchedules.map(s =>
                                                    <option key={"st-" + s.scheduleUnitID} value={s.scheduleUnitID}>
                                                        {s.scheduleUnitName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    ) : svcScheduleTypeName}
                                </td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>
                                    {/* creating service auto sets status to new */}
                                    {(mode === "edit" && userType === 1) ? (
                                        <div className="status-input-container">
                                            <select value={statusType} className="select status-select"
                                                onChange={(e) => setStatusType(e.target.value)}>
                                                {svcStatusTypes.map(s =>
                                                    <option key={"st-" + s.svcStatusID} value={s.svcStatusID}>
                                                        {s.svcStatusName}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    ) : statusTypeName}
                                </td>
                            </tr>
                            <tr>
                                <th>Assigned Employee(s)</th>
                                {((empNames.length === 0 && mode === "view") || (empNames.length === 0 && mode === "edit" && userType === 0)) && (
                                    <td>
                                        <i>No assigned employees.</i>
                                    </td>
                                )}
                                {(empNames.length !== 0 && mode === "view" && userType === 1 && canSeeEmps === true) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                <Link to={`/Employees/${e.value}`}>
                                                    {e.label}
                                                </Link>
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {((empNames.length !== 0 && mode === "edit" && userType === 0) || (empNames.length !== 0 && mode === "view" && userType === 0) || (empNames.length !== 0 && mode === "view" && canSeeEmps === false)) && (
                                    <td>
                                        {empNames.map(e =>
                                            <span key={"e-" + e.value}>
                                                {e.label}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                )}
                                {(mode === "create" && userType === 1) && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)" value={anEmpName === null ? "" : anEmpName} options={allEmps} onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                                {(mode === "edit" && userType === 1) && (
                                    <td>
                                        <Select isMulti placeholder="Select Employee(s)"
                                            value={empNames}
                                            options={allEmps}
                                            onChange={(e) => handleEmpSelect(e)} />
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                    <div className="pools-table-container">
                        <h2 className="section-header">Upon Arrival</h2>
                        <table className="pools-table">
                            <thead>
                                <tr>
                                    <th>Water Clarity</th>
                                    <th>Back Washed</th>
                                    <th>Skimmer Baskets Cleaned</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="text" className="text-field" value={waterClarity} onChange={(e) => setWaterClarity(e.target.value)} />
                                        ) : waterClarity}
                                    </td>
                                    <td>
                                        {(mode !== "edit" && backWashed === true) ? "Yes" : "No"}
                                        {(mode === "edit" && userType === 1) && (
                                            <Link to="#" className="checkbox" onClick={() => setBackWashed(!backWashed)}>
                                                {backWashed ? <i className="fa-solid fa-square-check fa-2x"></i> :
                                                    <i className="fa-solid fa-square fa-2x"></i>}
                                            </Link>
                                        )}
                                    </td>
                                    <td>
                                        {(mode !== "edit" && skimmerBsktsClean === true) ? "Yes" : "No"}
                                        {(mode === "edit" && userType === 1) && (
                                            <Link to="#" className="checkbox" onClick={() => setSkimmerBsktsClean(!skimmerBsktsClean)}>
                                                {skimmerBsktsClean ? <i className="fa-solid fa-square-check fa-2x"></i> :
                                                    <i className="fa-solid fa-square fa-2x"></i>}
                                            </Link>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="pools-table-container">
                        <h2 className="section-header">Original Readings</h2>
                        <table className="pools-table">
                            <thead>
                                <tr>
                                    <th>Pump Pressure</th>
                                    <th>CL</th>
                                    <th>PH</th>
                                    <th>TA</th>
                                    <th>CA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="text" className="text-field" value={origPumpPressure} onChange={(e) => setOrigPumpPressure(e.target.value)} />
                                        ) : origPumpPressure}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={origCL} onChange={(e) => setOrigCL(e.target.value)} />
                                        ) : origCL}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={origPH} onChange={(e) => setOrigPH(e.target.value)} />
                                        ) : origPH}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={origTA} onChange={(e) => setOrigTA(e.target.value)} />
                                        ) : origTA}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={origCA} onChange={(e) => setOrigCA(e.target.value)} />
                                        ) : origCA}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="pools-table-container">
                        <h2 className="section-header">Departure Readings</h2>
                        <table className="pools-table">
                            <thead>
                                <tr>
                                    <th>Pump Pressure</th>
                                    <th>CL</th>
                                    <th>PH</th>
                                    <th>TA</th>
                                    <th>CA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="text" className="text-field" value={departPumpPressure} onChange={(e) => setDepartPumpPressure(e.target.value)} />
                                        ) : departPumpPressure}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={departCL} onChange={(e) => setDepartCL(e.target.value)} />
                                        ) : departCL}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={departPH} onChange={(e) => setDepartPH(e.target.value)} />
                                        ) : departPH}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={departTA} onChange={(e) => setDepartTA(e.target.value)} />
                                        ) : departTA}
                                    </td>
                                    <td>
                                        {(mode === "edit" && userType === 1) ? (
                                            <input type="number" className="text-field" value={departCA} onChange={(e) => setDepartCA(e.target.value)} />
                                        ) : departCA}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                <div className="error">{error}</div>
                <div className="button-container">
                    {mode === "view" && (canEdit || userType === 0) && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {mode !== "view" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            {mode === "edit" && (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )}
                            {mode === "create" && (
                                <Link to="#" className="button"
                                    onClick={submit}>
                                    Submit
                                </Link>
                            )}
                        </>
                    )}
                </div>

                {mode === "view" && (<hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Notes</h2>
                )}
                {mode === "view" && (
                    <NoteList global={props.global} instId={-1} tskID={-1} servID={id} />
                )}

                {mode === "view" && (<hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Attachments</h2>
                )}
                {mode === "view" && (
                    <AttachList global={props.global} instId={-1} servID={id} acctID={-1} noteID={-1} />
                )}
            </div>
        </div>
    )
}

export default Service