const Loader = (props) => {
    return (
        <div className={props.isglobal ? 
            "loading global-loading" : "loading"}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
export default Loader;