import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Loader from './Loader.js';
import { GetEmployeeList } from '../API/EmployeeAPI.js';

const Employees = (props) => {
    const [loading, setLoading] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [employees, setEmployees] = useState([]);
    useEffect(() => {
        setLoading(true);
        GetEmployeeList(props.global, showInactive).then(response => {
            setLoading(false);
            if (response.success)
            {
                setEmployees(response.employees);
                setCanAdd(response.canAddEdit);
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, showInactive]);

    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Employees</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <div className="button-container">
                    {canAdd && (
                        <Link to="/Employees/new" className="button">
                            <i className="fa-solid fa-circle-plus"></i> Add New Employee
                        </Link>
                    )}
                    <Link to="#" className="checkbox" onClick={() => setShowInactive(!showInactive)}>
                        Show Inactive
                        {showInactive ? <i className="fa-solid fa-square-check fa-2x"></i> : 
                            <i className="fa-solid fa-square fa-2x"></i>}
                    </Link>
                </div>
                <div className="pools-table-container">
                    <table className="pools-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.length === 0 && (
                                <tr>
                                    <td colSpan="4">
                                        <i>No records found.</i>
                                    </td>
                                </tr>
                            )}
                            {employees.map(emp => 
                                <tr key={emp.userID}>
                                    <td>
                                        <Link to={`/Employees/${emp.userID}`}>
                                            {emp.lastName + ", " + emp.firstName}
                                        </Link>
                                    </td>
                                    <td>
                                        <a href={`mailto:${emp.email}`}>
                                            {emp.email}
                                        </a>
                                    </td>
                                    <td>
                                        <a href={`tel:${emp.primaryPhone}`}>
                                            {emp.primaryPhone}
                                        </a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Employees