import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import { GetAccountHolders } from '../../API/AccountAPI.js';
import Loader from '../Loader.js';

const AccountHolders = (props) => {
    let {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [accountHolders, setAccountHolders] = useState([]);
    useEffect(() => {
        setLoading(true);
        GetAccountHolders(props.global, id).then(response => {
            setAccountHolders(response.accountHolders);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            console.log("Error loading account holders");
        })
    }, [props.global, id])
    return (
        <div className="widget-container account-holders-container">
            {loading && <Loader />}
            <p>
                <Link to={`/Accounts/${id}/accountholders/new`} className="button">
                    <i className="fa-solid fa-circle-plus"></i> Add Account Holder
                </Link>
            </p>
            <div className="pools-table-container">
                <table className="pools-table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Primary Phone</th>
                            <th>Secondary Phone</th>
                        </tr>
                        {accountHolders.map(usr => 
                            <tr key={usr.userID}>
                                <td>
                                    <Link to={`/Accounts/${id}/accountholders/${usr.userID}`}>
                                        {`${usr.lastName}, ${usr.firstName}`}
                                    </Link>
                                </td>
                                <td>
                                    <a href={`mailto:${usr.email}`}>
                                        {usr.email}
                                    </a>
                                </td>
                                <td>
                                    <a href={`tel:${usr.primaryPhone}`}>
                                        {usr.primaryPhone} ({usr.primaryPhoneTypeName})
                                    </a>
                                </td>
                                <td>
                                    <a href={`tel:${usr.secondaryPhone}`}>
                                        {usr.secondaryPhone} ({usr.secondaryPhoneTypeName})
                                    </a>
                                </td>
                            </tr>    
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default AccountHolders;