import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader.js';
import { GetServiceCallList } from '../API/ServiceCallAPI.js';
import Pager from './Pager.js';

const Services = (props) => {
    const [loading, setLoading] = useState(false);
    const [showSomeStatuses, setShowStatuses] = useState(-1);
    const [canAdd, setCanAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [servicesInfo, setServicesInfo] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [pageNum, setPageNum] = useState(1);
    const [custs, setCusts] = useState([]);     // list of all custs
    const [selectedCust, setSelectedCust] = useState(-1);
    useEffect(() => {
        setLoading(true);
        GetServiceCallList(props.global,
            parseInt(showSomeStatuses) === -1 ? null : showSomeStatuses, selectedCust, pageNum, pageSize).then(response => {
                setLoading(false);
                if (response.success) {
                    setStatuses(response.svcStatuses);
                    setServicesInfo(response.svcCallsInfo);
                    setTotalResults(response.totalResults);
                    setCanAdd(response.canAddEdit);
                    setCusts(response.custs);
                }
            }).catch(() => {
                setLoading(false);
            })
    }, [props.global, showSomeStatuses, selectedCust, pageNum, pageSize]);

    const handleFilter = (e) => {
        setShowStatuses(e.target.value);
    }
    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Services</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <div className="button-container">
                    {canAdd && (
                        <Link to="/Services/new" className="button">
                            <i className="fa-solid fa-circle-plus"></i> Add New Service
                        </Link>
                    )}
                    <div className="dropdown-area">
                        {canAdd === true && (
                            <select value={selectedCust} className="select cust-select"
                                onChange={(e) => setSelectedCust(e.target.value)}>
                                <option value={-1}>All Customers</option>
                                {custs.map(c =>
                                    <option key={"c-" + c.userID} value={c.accountID}>
                                        {c.lastName}, {c.firstName}
                                    </option>
                                )}
                            </select>
                        )}
                    </div>
                    <div className="dropdown-area">
                        <select value={showSomeStatuses} className="select status-select"
                            onChange={(e) => handleFilter(e)}>
                            <option value={-1}>All Statuses</option>
                            {statuses.map(s =>
                                <option key={"s-" + s.svcStatusID} value={s.svcStatusID}>
                                    {s.svcStatusName}
                                </option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="pools-table-container">
                    <table className="pools-table">
                        <thead>
                            <tr>
                                <th>Account Address</th>
                                <th>Service Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {servicesInfo.length === 0 && (
                                <tr>
                                    <td colSpan="4">
                                        <i>No records found.</i>
                                    </td>
                                </tr>
                            )}
                            {servicesInfo.map(service => (
                                <tr key={service.serviceID}>
                                    <td>
                                        <Link to={`/Services/${service.serviceID}`}>
                                            {service.custAddress1}<br />
                                            {service.custAddress2}
                                            {service.custAddress2?.trim() !== "" && <br />}
                                            {service.custCity}, {service.custState} {service.custZip}
                                        </Link>
                                    </td>
                                    <td>
                                        {service.svcDate_fmt}
                                    </td>
                                    <td>
                                        {service.svcStatusName}
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                    <div className="pager">
                        <Pager totalResults={totalResults} pageSize={pageSize}
                            pageNum={pageNum} setPageNum={setPageNum} />
                        <select className="select" value={pageSize}
                            onChange={(e) => {
                                setPageNum(1); setPageSize(e.target.value)
                            }}>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services