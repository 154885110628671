import RoleList from './RoleList.js';
import RolePermissions from './RolePermissions.js';
import TaskTempList from './TaskTempList.js';
import ServiceTempList from './ServiceTempList.js';
const Permissions = (props) => {
    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Settings</h1>
            <div className="display-area-body">
                <h2 className="section-header">Roles</h2>
                <RoleList global={props.global} />
                <hr />
                <h2 className="section-header">Role Permissions</h2>
                <RolePermissions global={props.global} />
                <hr />
                <h2 className="section-header">Task Templates</h2>
                <TaskTempList global={props.global} />
                <hr />
                <h2 className="section-header">Service Templates</h2>
                <ServiceTempList global={props.global} />
            </div>
        </div>
    )
}

export default Permissions