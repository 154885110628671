import {Link} from 'react-router-dom';

const Pager = (props) => {
    let pageCount = Math.ceil(props.totalResults / props.pageSize)
    return [...Array(pageCount).keys()].map(p => 
        <Link to="#" className={ props.pageNum === p + 1 ? 
            "button pager-button pager-button-active" : 
            "button pager-button"}
            onClick={() => props.setPageNum(p + 1)}>
            {p + 1}
        </Link>
    );
}
export default Pager;