import {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {GetAccountHolder, SaveAccountHolder} from '../../API/AccountAPI';
import Loader from '../Loader.js';

const AccountHolder = (props) => {
    let {id} = useParams();
    let {accountid} = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [loading, setLoading] = useState(false);
    const [phoneTypes, setPhoneTypes] = useState([]);
    const [displayName, setDisplayName] = useState("Loading...");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [primaryPhone, setPrimaryPhone] = useState("");
    const [primaryPhoneType, setPrimaryPhoneType] = useState(0);
    const [primaryPhoneTypeName, setPrimaryPhoneTypeName] = useState("");
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [secondaryPhoneType, setSecondaryPhoneType] = useState(0);
    const [secondaryPhoneTypeName, setSecondaryPhoneTypeName] = useState("");
    
    useEffect(() => {
        setLoading(true);
        GetAccountHolder(props.global, id).then((response) => {
            if (!response.success)
            {
                history.push(`/Accounts/${accountid}`);
                setLoading(false);
                return;
            }
            setPhoneTypes(response.phoneTypes);
            let custinfo = response.customer;
            setDisplayName("New Account Holder");
            setLoading(false);
            if (id === -1)
            {
                setMode("edit");
                return;
            }
            setDisplayName(`${custinfo.lastName}, ${custinfo.firstName}`)
            setFirstName(custinfo.firstName);            
            setLastName(custinfo.lastName);
            setEmail(custinfo.email);
            setPrimaryPhone(custinfo.primaryPhone);
            setPrimaryPhoneType(custinfo.primaryPhoneTypeID);
            setPrimaryPhoneTypeName(custinfo.primaryPhoneTypeName);
            setSecondaryPhone(custinfo.secondaryPhone);
            setSecondaryPhoneType(custinfo.secondaryPhoneTypeID);
            setSecondaryPhoneTypeName(custinfo.secondaryPhoneTypeName);
        })
    }, [props.global, id, accountid, history, mode])
    const cancelClick = () => {
        if (id === -1)
        {
            history.push(`/Accounts/${accountid}`);
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        save();
    }
    const save = () => {
        setLoading(true);
        setError("");
        SaveAccountHolder(props.global, id, accountid, 
            firstName, lastName, primaryPhone,
            primaryPhoneType, secondaryPhone,
            secondaryPhoneType, email).then(response => {
                setLoading(false);
                if (!response.success)
                {
                    setError(response.message);
                    return;
                }
                if (id === -1)
                {
                    history.push(`/Accounts/${accountid}/accountholders/${response.userID}`);
                }
                setMode("view");
        }).catch(() => {
            setLoading(false);
            setError("An error occurred.  Please Try again later.");
        });
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to={`/Accounts/${accountid}`} className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e) }>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>First Name:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field" value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)} required />
                                    ) : firstName}
                                </td>
                            </tr>
                            <tr>
                                <th>Last Name:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field" value={lastName}
                                            onChange={(e) => setLastName(e.target.value)} required />
                                    ) : lastName}
                                </td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <input className="text-field email-field" value={email}
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    ) : email}
                                </td>
                            </tr>
                            <tr>
                                <th>Primary Phone:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <div className="phone-input-container">
                                            <PhoneInput
                                                placeholder=""
                                                defaultCountry='US'
                                                country="US"
                                                value={primaryPhone}
                                                onChange={setPrimaryPhone} />
                                            <select value={primaryPhoneType} className="select phone-select"
                                                onChange={(e) => setPrimaryPhoneType(e.target.value)}>
                                                {phoneTypes.map(p => 
                                                    <option key={"p-" + p.phoneTypeID} value={p.phoneTypeID}>
                                                        {p.phoneTypeName}
                                                    </option>    
                                                )}
                                            </select>
                                        </div>
                                        
                                    ) : 
                                        primaryPhone + " (" + primaryPhoneTypeName + ")"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Secondary Phone:</th>
                                <td>
                                    {mode === "edit" ? (
                                        <div className="phone-input-container">
                                            <PhoneInput
                                                placeholder=""
                                                defaultCountry='US'
                                                country="US"
                                                value={secondaryPhone}
                                                onChange={setSecondaryPhone} />
                                            <select value={secondaryPhoneType} className="select phone-select"
                                                onChange={(e) => setSecondaryPhoneType(e.target.value)}>
                                                {phoneTypes.map(p => 
                                                    <option key={"s-" + p.phoneTypeID} value={p.phoneTypeID}>
                                                        {p.phoneTypeName}
                                                    </option>    
                                                )}
                                            </select>
                                        </div>
                                        
                                    ) : 
                                        (secondaryPhone ?? "") + " (" + secondaryPhoneTypeName + ")"
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className="button-container">
                    {mode !== "edit" && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {mode === "edit" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            <Link to="#" className="button"
                                onClick={save}>
                                Save
                            </Link>
                        </>
                    )}
                </div>
                <div className="error">{error}</div>
            </div>
        </div>
    )
}
export default AccountHolder;