import APIRequest from './APIRequest.js';

export const Authenticate = (global, email, password) => {
    return APIRequest({
        global: global,
        url: 'Auth/Authenticate',
        method: 'post',
        body: {
            "email": email,
            "password": password
        }
    })
}
export const CheckAuth = (global) => {
    return APIRequest({
        global: global,
        url: 'Auth/CheckAuth',
        method: 'get'
    })
}
export const SetPassword = (global, password) => {
    return APIRequest({
        global: global,
        url: 'Auth/SetPassword',
        method: 'post',
        body: {
            "password": password
        }
    })
}
export const ResetPassword = (global, email) => {
    return APIRequest({
        global: global,
        url: 'Auth/ResetPassword',
        method: 'post',
        body: {
            "email": email
        }
    })
}
export const Register = (global, address1, address2,
    city, state, zip, firstName, lastName, primaryPhone,
    primaryPhoneTypeID, secondaryPhone,
    secondaryPhoneTypeID, email, password) => {
        return APIRequest({
            global: global,
            url: 'Auth/Register',
            method: 'post',
            body: {
                "accountInfo": {
                    "address1": address1,
                    "address2": address2,
                    "city": city,
                    "state": state,
                    "zip": zip,
                    "accountHolders": [
                        {
                            "firstName": firstName,
                            "lastName": lastName,
                            "primaryPhone": primaryPhone,
                            "primaryPhoneTypeID": primaryPhoneTypeID,
                            "secondaryPhone": secondaryPhone,
                            "secondaryPhoneTypeID": secondaryPhoneTypeID,
                            "email": email
                        }
                    ]
                },
                "password": password
            }
        })
}
export const Logout = (global) => {
    return APIRequest({
        global: global,
        url: 'Auth/Logout',
        method: 'get'
    });
}