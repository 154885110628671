const APIRequest = (params) => {
    return new Promise((resolve, reject) => {
        let poolsToken = localStorage.getItem("pools-token");
        if (poolsToken === null)
            poolsToken = sessionStorage.getItem("pools-token");
        const { apiBaseURL } = window['runConfig'];
        const headers = {
            'Accept': params.respType === 'raw' ? 'blob' : 'application/json',
            'Content-Type': 'application/json'
        };

        if (poolsToken !== null)
            headers['Authorization'] = `Bearer ${poolsToken}`

        fetch(`${apiBaseURL}/api/${params.url}`, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                if (!params.global === undefined)
                {
                    params.global.setAuthenticated(false);
                    params.global.setLoading(false);
                }
                reject(response);
            }
            if (!response.ok) {
                throw Error(response)
            }
            if (params.respType === "raw") {
                return response.blob();
            }
            return response.json();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}
export default APIRequest;