import {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Register} from '../API/AuthAPI.js';
import { CreateAccount } from '../API/AccountAPI.js';
import {GetPhoneTypes} from '../API/EnumAPI';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Loader from './Loader.js';

const Registration = (props) => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [phoneTypes, setPhoneTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [primaryPhone, setPrimaryPhone] = useState("");
    const [primaryPhoneType, setPrimaryPhoneType] = useState(0);
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [secondaryPhoneType, setSecondaryPhoneType] = useState(0);
    const [address, setAddress] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    useEffect(() => {
        setLoading(true);
        GetPhoneTypes(props.global).then(response => {
            setPhoneTypes(response.phoneTypes);
            const { apiBaseURL } = window['runConfig'];
            return fetch(`${apiBaseURL}/states.json`)
        }).then(response => {
            return response.json();
        }).then(response => {
            setStates(response);
            setState("PA");
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }, [props.global])
    const submitForm = (e) => {
        e.preventDefault();
        register();
    }
    const register = () => {
        setError("");
        if (firstName.trim() === "")
        {
            setError("First name is required."); return;
        }
        if (lastName.trim() === "")
        {
            setError("Last name is required."); return;
        }
        if (email.trim() === "")
        {
            setError("Email is required."); return;
        }
        if (address.trim() === "")
        {
            setError("Address is required."); return;
        }
        if (city.trim() === "")
        {
            setError("Address is required."); return;
        }
        if (zip.trim() === "")
        {
            setError("Address is required."); return;
        }
        setLoading(true)
        // Registration
        if (props.isCreateAcct)
        {
            // Call API endpoint for account creation
            // by an admin
            CreateAccount(props.global, address, address2,
                city, state, zip, firstName, lastName,
                primaryPhone, primaryPhoneType, secondaryPhone,
                secondaryPhoneType, email).then(response => {
                    history.push(`/Accounts/${response.accountID}`);
            }).catch(() => {
                setLoading(false);
                let errmsg = "An error occurred while registering your ";
                errmsg += "account.  Please try again later.";
                setError(errmsg)
            })
            return;
        }
        Register(props.global, address, address2,
            city, state, zip, firstName, lastName,
            primaryPhone, primaryPhoneType, secondaryPhone,
            secondaryPhoneType, email, password).then(response => {
                setLoading(false);
                if (!response.success)
                {
                    setError(response.message);
                    return;
                }
                localStorage.setItem("pools-token", response.token);
                props.global.setAuthenticated(true);
            }).catch(() => {
                setLoading(false);
                let errmsg = "An error occurred while registering your ";
                errmsg += "account.  Please try again later.";
                setError(errmsg)
            })
    }
    return (
        <div className={!props.isCreateAcct ? "login-container" : ""}>
            {loading && <Loader isglobal={true} />}
            <div className={!props.isCreateAcct ? "login-dialog" : ""}>
            {!props.isCreateAcct && <div className="login-header">Register</div>}
                <div className="login-body">
                    <form className="form" onSubmit={(e) => submitForm(e) }>
                        <label>Email:</label>
                        <input type="text" className="text-field" autoComplete="email"
                            value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <label>First Name:</label>
                        <input type="text" className="text-field" autoComplete="first name"
                            value={firstName} onChange={(e) => setFirstName(e.target.value)} 
                            required />
                        <label>Last Name:</label>
                        <input type="text" className="text-field" autoComplete="last name"
                            value={lastName} onChange={(e) => setLastName(e.target.value)} 
                            required />
                        {!props.isCreateAcct && (
                            <>
                                <label>Password:</label>
                                <input type="password" className="text-field" autoComplete="password"
                                    value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <label>Confirm Password:</label>
                                <input type="password" className="text-field" autoComplete="password"
                                    value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                    required />
                            </>
                        )}
                        <label>Primary Phone:</label>
                        <div className="phone-input-container">
                            <PhoneInput
                                placeholder=""
                                defaultCountry='US'
                                country="US"
                                value={primaryPhone}
                                onChange={setPrimaryPhone} />
                            <select value={primaryPhoneType} className="select phone-select"
                                onChange={(e) => setPrimaryPhoneType(e.target.value)}>
                                {phoneTypes.map(p => 
                                    <option key={"p-" + p.phoneTypeID} value={p.phoneTypeID}>
                                        {p.phoneTypeName}
                                    </option>    
                                )}
                            </select>
                        </div>
                        <label>Secondary Phone (optional):</label>
                        <div className="phone-input-container">
                            <PhoneInput
                                placeholder=""
                                defaultCountry='US'
                                country="US"
                                value={secondaryPhone}
                                onChange={setSecondaryPhone} />
                            <select value={secondaryPhoneType} className="select phone-select"
                                onChange={(e) => setSecondaryPhoneType(e.target.value)}>
                                {phoneTypes.map(p => 
                                    <option key={"s-" + p.phoneTypeID} value={p.phoneTypeID}>
                                        {p.phoneTypeName}
                                    </option>    
                                )}
                            </select>
                        </div>
                        <label>Address:</label>
                        <input type="text" className="text-field" autoComplete="address"
                            value={address} onChange={(e) => setAddress(e.target.value)}
                            required />
                        <input type="text" className="text-field" autoComplete="address2"
                            value={address2} onChange={(e) => setAddress2(e.target.value)} />
                        <label>City:</label>
                        <input type="text" className="text-field" autoComplete="city"
                            value={city} onChange={(e) => setCity(e.target.value)}
                            required />
                        <label>State:</label>
                        <div className="center-input">
                            <select className="select" value={state} required
                                onChange={(e) => setState(e.target.value)}>
                                    <option value="">-- Select State --</option>
                                {states.map(s => 
                                    <option key={s.abbreviation} value={s.abbreviation}>
                                        {s.name}
                                    </option>
                                )}
                            </select>
                        </div>
                        <label>Zipcode:</label>
                        <input type="text" className="text-field" autoComplete="zip"
                            value={zip} onChange={(e) => setZip(e.target.value)}
                            required />
                        <div className="button-container">
                            <div className="error">{error}</div>
                        </div>
                        <input type="submit" value="" className="form-submit" />
                        <div className="button-container">
                            <Link to={props.isCreateAcct ? "/Accounts" : "/"} className="button">
                                Cancel
                            </Link>
                            <Link to="#" className="button"
                                onClick={register}>
                                    {props.isCreateAcct ? "Save" : "Register"}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Registration;