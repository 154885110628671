import { Link, useHistory } from 'react-router-dom';
import { Logout } from '../API/AuthAPI';

const Header = (props) => {
    let history = useHistory();
    const logoutButtonClick = () => {
        Logout(props.global).then(() => {
            if (localStorage.getItem("pools-token") !== null)
                localStorage.removeItem("pools-token");
            if (sessionStorage.getItem("pools-token") !== null)
                localStorage.removeItem("pools-token");
            props.global.setAuthenticated(false);
            history.push("/");
        }).catch(() => {
            console.log("Error logging out?!?");
            //props.global.setAuthenticated(false);
            //history.push("/");
        });
    }
    return (
        <div className="header-container">
            <div className="header">
                <div className="social-icons">
                    <a href="https://www.facebook.com/Pristine-Pools-570900716596662" target="_blank" rel="noreferrer" 
                        className="social-media-icon facebook-icon" alt="Pristine Pools on Facebook">Facebook</a>
                    <a href="http://instagram.com/pristine_pools" target="_blank" rel="noreferrer" 
                        className="social-media-icon instagram-icon" alt="Pristine Pools on Instagram">Instagram</a>
                </div>
                {props.authenticated && (
                    <Link to="#" className="button logout-button"
                        onClick={() => logoutButtonClick()}>
                        Log Out
                    </Link>
                )}
            </div>
            <div className="header2">
                <img src="images/main_logo.png" alt="Pristine Pools" />
            </div>
        </div>
    )
}

export default Header