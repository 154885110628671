import APIRequest from './APIRequest.js';

export const GetRoleList = (global) => {
    return APIRequest({
        global: global,
        url: 'Access/GetRoleList',
        method: 'get'
    });
}
export const GetRole = (global, roleID) => {
    return APIRequest({
        global: global,
        url: 'Access/GetRole',
        method: 'post',
        body: {
            "roleID": roleID
        }
    });
}
export const SaveRole = (global, roleID, name, description) => {
    return APIRequest({
        global: global,
        url: 'Access/SaveRole',
        method: 'post',
        body: {
            "role": {
                "roleID": roleID,
                "name": name,
                "description": description
            }           
        }
    });
}
export const GetPermissionList = (global, roleID) => {
    return APIRequest({
        global: global,
        url: 'Access/GetPermissionList',
        method: 'post',
        body: {
            "roleID": roleID
        }
    })
}
/*
    permissions is an array of objects that look like this:
    {
        permissionID: 1
        granted: true 
    }
*/
export const SetPermissions = (global, roleID, permissions) => {
    return APIRequest({
        global: global,
        url: 'Access/SetPermissions',
        method: 'post',
        body: {
            "roleID": roleID,
            "permissions": permissions
        }
    })
}

export const GetUserPreferences = (global) => {
    return APIRequest({
        global: global,
        url: 'Access/GetUserPreferences',
        method: 'post',
    })
}
/*
    preferences is an array of objects that look like this:
    {
        prefsID: 1
        notifs: true
    }
*/
export const SetUserPreferences = (global, preferences) => {
    return APIRequest({
        global: global,
        url: 'Access/SetUserPreferences',
        method: 'post',
        body: {
            "userPrefs": preferences,
        }
    })
}