import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Authenticate} from '../API/AuthAPI.js';
import Loader from './Loader.js';

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const submitLoginForm = (e) => {
      e.preventDefault();
      submitLogin()
    }
    const submitLogin = () => {
      setError("");
      setLoading(true);
      Authenticate(props.global, email, 
        password).then(response => {
        console.log(response);
        if (!response.success)
        {
          setError(response.message);
          setLoading(false);
          return;
        }
        localStorage.setItem("pools-token", response.token);
        props.global.setAuthenticated(true);
        props.global.setChangePassword(response.changePassword);
      }).catch(() => {
        setError("Services are currently unavailable.  Please try again later.");
        setLoading(false);
      })
    }
    return (
        <div className="login-container">
          {loading && <Loader />}
          <div className="login-dialog">
            <div className="login-header">Login</div>
            <div className="login-body">
              <form className="form" onSubmit={(e) => submitLoginForm(e) }>
                <label>Email:</label>
                <input type="text" className="text-field" autoComplete="email"
                  value={email} onChange={(e) => setEmail(e.target.value)} />
                <label>Password:</label>
                <input type="password" className="text-field" autoComplete="password"
                  value={password} onChange={(e) => setPassword(e.target.value)} />

                <input type="submit" value="" className="form-submit" />
                <div className="error">{error}</div>
                <div className="button-container">
                  <Link to="#" className="button" onClick={() => submitLogin()}>
                    Login</Link>
                </div>
                <div className="button-container">
                  <Link to="/PasswordReset" className="button">Forgot Password?</Link>
                </div>
                <div className="button-container">
                  <Link to="/Register" className="button">Register for an Account!</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
    )
}

export default Login;