import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Loader from './Loader.js';
import { SetDeviceTokens, GetUpcomingInstalls, GetTasks, GetUpcomingServices, GetPastDueInstalls, GetPastDueServices } from '../API/DashboardAPI';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import { FCM } from '@awesome-cordova-plugins/fcm';

const Dashboard = (props) => {
    const [loading, setLoading] = useState(false);
    //const [canAdd, setCanAdd] = useState(false);
    const [isCust, setIsCust] = useState(true);
    const [showPastDue, setShowPastDue] = useState(false);
    const [upcomingInstalls, setUpcomingInstalls] = useState([]);
    //const [upInstallResults, setUpInstallResults] = useState(0);
    const [tasks, setTasks] = useState([]);
    //const [taskResults, setTaskResults] = useState(0);
    const [upcomingSvcs, setUpcomingSvcs] = useState([]);
    //const [upSvcResults, setUpSvcResults] = useState(0);
    const [pastDueInstalls, setPastDueInstalls] = useState([]);
    //const [pastInstallResults, setPastInstallResults] = useState(0);
    const [pastDueSvcs, setPastDueSvcs] = useState([]);
    //const [pastSvcResults, setPastSvcResults] = useState(0);

    //async function theToken(_token) {
    //    let tokenInfo = await window.cordova.plugins.FCM.getToken();
    //    if (tokenInfo !== null) {
    //        _token = tokenInfo.ToString();
    //    }
    //    else {
    //        let newTokenInfo = await window.cordova.plugins.FCM.onTokenRefresh();
    //        _token = newTokenInfo.ToString();
    //    }
    //}

    useEffect(async () => {
        let deviceToken = "";
        let isAndroidDevice = false;
        let hasPerm = false;
        let wasPermGiven = false;
        if (window.cordova !== undefined && window.cordova.platformId !== undefined) {
            if (window.cordova.platformId !== "ios") {
                isAndroidDevice = true;
            }
            else if (window.cordova.platformId === "ios") {
                isAndroidDevice = false;
            }
            wasPermGiven = await window.FCM.requestPushPermission();
            hasPerm = await window.FCM.hasPermission();
        }

        setLoading(true);
        if (hasPerm === true && wasPermGiven === true) {
            SetDeviceTokens(props.global, await window.FCM.getToken(), isAndroidDevice).then(response => {
                setLoading(false);
                console.log(response);
            })
        }
        else {
            SetDeviceTokens(props.global, deviceToken, isAndroidDevice).then(response => {
                setLoading(false);
                console.log(response);
            })
        }
    }, [props.global])

    useEffect(() => {
        setLoading(true)
        GetUpcomingInstalls(props.global).then(response => {
            setLoading(false)
            // console.log(response)
            if (response.success) {
                //console.log("upcoming installs")
                //setCanAdd(response.canAddEdit);
                setUpcomingInstalls(response.installsInfo)
                //setUpInstallResults(response.totalResults)
            }
        })
    }, [props.global])

    useEffect(() => {
        setLoading(true)
        GetTasks(props.global).then(response => {
            setLoading(false)
            setShowPastDue(response.showPastDue)
            setIsCust(response.isCust);
            if (response.success) {
                //console.log("tasks")
                setTasks(response.tasks)
                //setTaskResults(response.totalResults)
            }
        })
    }, [props.global])

    useEffect(() => {
        setLoading(true)
        GetUpcomingServices(props.global).then(response => {
            setLoading(false)
            if (response.success) {
                //console.log("upcoming svcs")
                setUpcomingSvcs(response.svcCallsInfo)
                //setUpSvcResults(response.totalResults)
            }
        })
    }, [props.global])

    useEffect(() => {
        setLoading(true)
        GetPastDueInstalls(props.global).then(response => {
            //console.log(response)
            setLoading(false)
            if (response.success) {
                //console.log("past due installs")
                //console.log(response)
                setPastDueInstalls(response.installsInfo)
                //setPastInstallResults(response.totalResults)
            }
        })
    }, [props.global])

    useEffect(() => {
        setLoading(true)
        GetPastDueServices(props.global).then(response => {
            setLoading(false)
            if (response.success) {
                //console.log("past due svcs")
                setPastDueSvcs(response.svcCallsInfo)
                //setPastSvcResults(response.totalResults)
            }
        })
    }, [props.global])

    return (
        <div className="display-area-main">
            <h1 className="display-area-header">Dashboard</h1>
            <div className="display-area-body">
                {loading && <Loader />}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>Installation Tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <div className="pools-table-container">
                                <table className="pools-table">
                                    <thead>
                                        <tr>
                                            <th>Task Name</th>
                                            <th>Start Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.length === 0 && (
                                            <tr>
                                                {isCust === true ? (
                                                    <td colSpan="3">
                                                        <i>No tasks found due to there being no ongoing installation.</i>
                                                    </td>
                                                ) : (
                                                    <td colSpan="3">
                                                        <i>No tasks found.</i>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {tasks.map(task => (
                                            <tr key={'t-' + task.taskID}>
                                                <td>
                                                    <Link to={`/Installations/${task.installID}/Tasks/${task.taskID}`}>
                                                        {task.taskName}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {task.startDate_fmt}
                                                </td>
                                                <td>
                                                    {task.statusTypeName}
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        {isCust === true ? (
                            <Typography>Your Installation</Typography>
                        ) : (
                            <Typography>Upcoming Installations</Typography>)
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <div className="pools-table-container">
                                <table className="pools-table">
                                    <thead>
                                        <tr>
                                            <th>Account Address</th>
                                            <th>Start Date</th>
                                            {isCust === true && (<th>Status</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {upcomingInstalls.length === 0 && (
                                            <tr>
                                                {isCust === true ? (
                                                    <td colSpan="3">
                                                        <i>No ongoing installation found.</i>
                                                    </td>
                                                ) : (
                                                    <td colSpan="2">
                                                        <i>No upcoming installations found.</i>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                        {upcomingInstalls.map(install => (
                                            <tr key={'ui-' + install.installID}>
                                                <td>
                                                    <Link to={`/Installations/${install.installID}`}>
                                                        {install.custAddress1}<br />
                                                        {install.custAddress2}
                                                        {install.custAddress2?.trim() !== "" && <br />}
                                                        {install.custCity}, {install.custState} {install.custZip}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {install.startDate_fmt}
                                                </td>
                                                {isCust === true && (
                                                    <td>
                                                        {install.statusTypeName}
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header">
                        <Typography>Upcoming Services</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component={'span'}>
                            <div className="pools-table-container">
                                <table className="pools-table">
                                    <thead>
                                        <tr>
                                            <th>Account Address</th>
                                            <th>Service Date</th>
                                            {/*<th>Status</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {upcomingSvcs.length === 0 && (
                                            <tr>
                                                <td colSpan="2">
                                                    <i>No upcoming services found.</i>
                                                </td>
                                            </tr>
                                        )}
                                        {upcomingSvcs.map(service => (
                                            <tr key={'us-' + service.serviceID}>
                                                <td>
                                                    <Link to={`/Services/${service.serviceID}`}>
                                                        {service.custAddress1}<br />
                                                        {service.custAddress2}
                                                        {service.custAddress2?.trim() !== "" && <br />}
                                                        {service.custCity}, {service.custState} {service.custZip}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {service.svcDate_fmt}
                                                </td>
                                                {/*<td>*/}
                                                {/*    {service.svcStatusName}*/}
                                                {/*</td>*/}
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {showPastDue === true && (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header">
                            <Typography>Past-Due Installations</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'span'}>
                                <div className="pools-table-container">
                                    <table className="pools-table">
                                        <thead>
                                            <tr>
                                                <th>Account Address</th>
                                                <th>Start Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pastDueInstalls.length === 0 && (
                                                <tr>
                                                    <td colSpan="3">
                                                        <i>No past-due installations found.</i>
                                                    </td>
                                                </tr>
                                            )}
                                            {pastDueInstalls.map(install => (
                                                <tr key={'pdi-' + install.installID}>
                                                    <td>
                                                        <Link to={`/Installations/${install.installID}`}>
                                                            {install.custAddress1}<br />
                                                            {install.custAddress2}
                                                            {install.custAddress2?.trim() !== "" && <br />}
                                                            {install.custCity}, {install.custState} {install.custZip}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {install.startDate_fmt}
                                                    </td>
                                                    <td>
                                                        {install.statusTypeName}
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {showPastDue === true && (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header">
                            <Typography>Past-Due Services</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'span'}>
                                <div className="pools-table-container">
                                    <table className="pools-table">
                                        <thead>
                                            <tr>
                                                <th>Account Address</th>
                                                <th>Service Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pastDueSvcs.length === 0 && (
                                                <tr>
                                                    <td colSpan="3">
                                                        <i>No past-due services found.</i>
                                                    </td>
                                                </tr>
                                            )}
                                            {pastDueSvcs.map(service => (
                                                <tr key={'pds-' + service.serviceID}>
                                                    <td>
                                                        <Link to={`/Services/${service.serviceID}`}>
                                                            {service.custAddress1}<br />
                                                            {service.custAddress2}
                                                            {service.custAddress2?.trim() !== "" && <br />}
                                                            {service.custCity}, {service.custState} {service.custZip}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {service.svcDate_fmt}
                                                    </td>
                                                    <td>
                                                        {service.svcStatusName}
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        </div>
    )
}

export default Dashboard;