import {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {GetRole, SaveRole} from '../API/AccessAPI.js';
import Loader from './Loader.js';

const Role = (props) => {
    const [loading, setLoading] = useState(false);
    const [displayName, setDisplayName] = useState("New Role");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("")
    let {id} = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    useEffect(() => {
        if (id === -1)
        {
            return;
        }
        setLoading(true);
        GetRole(props.global, id).then(response => {
            setDisplayName(response.role.name);
            setName(response.role.name);
            setDescription(response.role.description);
            setLoading(false);
        })
    }, [props.global, id])
    const submitForm = (e) => {
        e.preventDefault();
        saveRole();
    }
    const saveRole = () => {
        setLoading(true);
        SaveRole(props.global, id, name, description).then(response => {
            if (!response.success)
            {
                setError(response.message);    
                return;
            }
            history.push("/Permissions");
        }).catch(() => {
            setLoading(false);
            setError("Error occurred while saving role.  Please try again later.");
        })
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Permissions" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e) }>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>
                                    <input className="text-field" value={name}
                                        onChange={(e) => setName(e.target.value)} required />
                                </td>
                            </tr>
                            <tr>
                                <th>Description:</th>
                                <td>
                                    <textarea className="text-field large-text-field" value={description}
                                        onChange={(e) => setDescription(e.target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="button-container">
                    <Link to="/Permissions" className="button">
                        Cancel
                    </Link>
                    <Link to="#" className="button"
                        onClick={saveRole}>
                        Save
                    </Link>
                    </div>
                    <div className="error">{error}</div>
                </form>
            </div>
        </div>
    )
}

export default Role;