import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetServiceCallTemp, CreateServiceCallTemp, SaveServiceCallTemp } from '../API/ServiceCallAPI';
import Loader from './Loader.js';

const ServiceTemp = (props) => {
    let { id } = useParams();
    let history = useHistory();
    if (id === "new")
        id = -1;
    const [svcTempName, setSvcTempName] = useState("");
    const [timeVal, setTimeVal] = useState(0.0);
    const [svcUnitValId, setSvcUnitValId] = useState(0);
    const [scheduleUnitValId, setScheduleUnitValId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [svcTypeId, setSvcTypeId] = useState(0);
    //const [canAdd, setCanAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [timeUnits, setTimeUnits] = useState([]);
    const [scheduleUnits, setScheduleUnits] = useState([]);
    const [svcTypes, setSvcTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [displayName, setDisplayName] = useState("New Service Template");
    useEffect(() => {
        setLoading(true);
        GetServiceCallTemp(props.global, id).then(response => {
            setLoading(false);
            if (!response.success) {
                history.push('/Permissions');
                return;
            }
            if (response.success) {
                setTimeUnits(response.svcTimeUnits);
                setStatuses(response.svcStatuses);
                setScheduleUnits(response.scheduleUnits);
                setSvcTypes(response.svcTypes);
                //console.log(response.svcTypes)
                if (id === -1) {
                    setMode("create");
                    return;
                }
                let svcTemp = response.svcCallTemp;
                setSvcTempName(svcTemp.svcTempName);
                setTimeVal(svcTemp.timeVal);
                setSvcUnitValId(svcTemp.svcUnitValID);
                setScheduleUnitValId(svcTemp.scheduleUnitValID);
                setStatusId(svcTemp.statusID);
                setSvcTypeId(svcTemp.vcTypeID);
                //setCanAdd(response.canAddEdit);
                setMode("edit");
                setDisplayName("Service Template");
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [props.global, id, history, mode])
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (svcTempName.trim() === "") {
            setError("A template name is required."); return;
        }
        if (timeVal === 0) {
            setError("Service length is required."); return;
        }
        setLoading(true);
        SaveServiceCallTemp(props.global, id, svcTempName, timeVal, svcUnitValId, scheduleUnitValId, statusId, svcTypeId).then(response => {
            if (!response.success) {
                setError(response.message);
                return;
            }
            history.push("/Permissions");
        }).catch(() => {
            setLoading(false);
            setError("Error occurred while saving template.  Please try again later.");
        })
    }
    const submit = () => {
        setError("");
        if (svcTempName.trim() === "") {
            setError("A template name is required."); return;
        }
        if (timeVal === 0) {
            setError("Service length is required."); return;
        }
        setLoading(true);
        CreateServiceCallTemp(props.global, id, svcTempName, timeVal, svcUnitValId, scheduleUnitValId, statusId, svcTypeId).then(response => {
            if (!response.success) {
                setError(response.message);
                return;
            }
            history.push("/Permissions");
        }).catch(() => {
            setLoading(false);
            setError("Error occurred while creating template.  Please try again later.");
        })
    }
    const handleUnitValSelect = (e) => {
        setSvcUnitValId(e.target.value);
    }
    const handleScheduleUnitSelect = (e) => {
        setScheduleUnitValId(e.target.value);
    }
    const handleSvcTypeSelect = (e) => {
        setSvcTypeId(e.target.value);
    }
    const handleStatusSelect = (e) => {
        setStatusId(e.target.value);
    }
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Permissions" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {loading && <Loader />}
                <form className="form" onSubmit={(e) => submitForm(e)}>
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Template Name:</th>
                                <td>
                                    <input className="text-field" value={svcTempName} onChange={(e) => setSvcTempName(e.target.value)} required />
                                </td>
                            </tr>
                            <tr>
                                <th>Service Length:</th>
                                <td>
                                    <input type="number" className="text-field" value={timeVal} onChange={(e) => setTimeVal(e.target.value)} required min="0" />
                                    <select className="select" value={svcUnitValId} onChange={(e) => handleUnitValSelect(e)}>
                                        {timeUnits.map(tu =>
                                            <option key={tu.timeID} value={tu.timeID}>
                                                {tu.timeName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Occurence:</th>
                                <td>
                                    <select className="select" value={scheduleUnitValId} onChange={(e) => handleScheduleUnitSelect(e)}>
                                        {scheduleUnits.map(s =>
                                            <option key={s.scheduleUnitID} value={s.scheduleUnitID}>
                                                {s.scheduleUnitName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Type:</th>
                                <td>
                                    <select className="select" value={svcTypeId} onChange={(e) => handleSvcTypeSelect(e)}>
                                        {svcTypes.map(s =>
                                            <option key={s.serviceTypeID} value={s.serviceTypeID}>
                                                {s.serviceTypeName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Status:</th>
                                <td>
                                    <select className="select" value={statusId} onChange={(e) => handleStatusSelect(e)}>
                                        {statuses.map(s =>
                                            <option key={s.svcStatusID} value={s.svcStatusID}>
                                                {s.svcStatusName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="error">{error}</div>
                    <div className="button-container">
                        <Link to="/Permissions" className="button">
                            Cancel
                        </Link>
                        {mode === "create" ?
                            (
                                <Link to="#" className="button"
                                    onClick={submit}>
                                    Submit
                                </Link>
                            ) :
                            (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ServiceTemp;