import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetNote, CreateNote, SaveNote } from '../API/InstallationAPI';
import AttachList from './AttachList.js';
import Loader from './Loader.js';

const Note = (props) => {
    let { instID, taskID, noteID, svcID } = useParams();   // add service id
    let history = useHistory();
    if (noteID === "new")
        noteID = -1;
    const [noteName, setNoteName] = useState("");
    const [noteDescript, setNoteDescript] = useState("");
    const [noteTime, setNoteTime] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [noteTime_fmt, setNoteTime_fmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    //const [taskId, setTaskId] = useState(-1);
    //const [installId, setInstallId] = useState(-1);
    const [userId, setUserId] = useState(-1);
    const [userFN, setUserFN] = useState("");
    const [userLN, setUserLN] = useState("");
    //const [serviceId, setServiceId] = useState(-1);   // need to comment out when service id is passed in params
    const [status, setStatus] = useState(0);
    const [statusName, setStatusName] = useState("Active");
    const [statuses, setStatuses] = useState([]);
    const [flag, setFlag] = useState(1);
    const [flagName, setFlagName] = useState("External");
    const [flags, setFlags] = useState([]);
    const [canSeeInternal, setCanSeeInternal] = useState(false);
    const [canSeeInactive, setCanSeeInactive] = useState(false);
    //const [canEdit, setCanEdit] = useState(false);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("view");
    const [displayName, setDisplayName] = useState("Loading...");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetNote(props.global, noteID).then(response => {
            if (!response.success) {
                history.push('/');
                setLoading(false);
                return;
            }
            let noteInfo = response.note;
            setStatuses(response.noteStatuses);
            setFlags(response.flags);
        // add for service id <- huh?
            setCanSeeInactive(response.canSeeInactive);
            setCanSeeInternal(response.canSeeInternal);
            setLoading(false);
            if (noteID === -1) {
                setDisplayName("New Note");
                setMode("create");
                return;
            }
            setDisplayName("Note");
            setNoteName(noteInfo.noteName);
            setNoteDescript(noteInfo.noteDescript);
            setNoteTime(noteInfo.noteTime);
            setNoteTime_fmt(noteInfo.noteTime_fmt);
            //setTaskId(noteInfo.taskID);
            //setInstallId(noteInfo.installID);
            setUserId(noteInfo.userID);
            setUserFN(noteInfo.userFName);
            setUserLN(noteInfo.userLName);
            //setServiceId(noteInfo.svcID);   // need to comment out when service id is passed in params
            setStatus(noteInfo.status);
            setStatusName(noteInfo.statusName);
            setFlag(noteInfo.flag);
            setFlagName(noteInfo.flagName);
        })
    }, [props.global, mode, history, noteID])
    const cancelClick = () => {
        if (noteID === -1) {
            if (instID !== -1 && instID !== null && instID !== undefined) {
                history.push(`/Installations/${instID}`);
            }
            //else if ((instID !== -1 && instID !== null) && (taskID !== -1 && taskID !== null)) {
            //    history.push(`/Installations/${instID}/Tasks/${taskID}`);
            //}
            else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                history.push(`/Services/${svcID}`);
            }
            else {
                history.push("/");
            }
            return;
        }
        setError("");
        setMode("view");
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (noteName.trim() === "") {
            setError("A note name is required."); return;
        }
        if (noteDescript.trim() === "") {
            setError("A description is required."); return;
        }
        setLoading(true);
        SaveNote(props.global, noteID, noteName, noteDescript, noteTime, taskID, instID, userId, svcID, status, flag).then(response => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return;
            }
            if (noteID === -1) {
                if (instID !== -1 && instID !== null && instID !== undefined) {
                    history.push(`/Installations/${instID}`);
                }
                //else if ((instID !== -1 && instID !== null) && (taskID !== -1 && taskID !== null)) {
                //    history.push(`/Installations/${response.note.installID}/Tasks/${response.note.taskID}`);
                //}
                else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                    history.push(`/Services/${svcID}`);
                }
                else {
                    history.push("/");
                }
            }
            setMode("view");
        })
    }
    const deleteNote = () => {
        setError("");
        setLoading(true);
        SaveNote(props.global, noteID, noteName, noteDescript, noteTime, taskID, instID, userId, svcID, 1, flag).then(response => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return;
            }
            if (instID !== -1 && instID !== null && instID !== undefined) {
                history.push(`/Installations/${instID}`);
            }
            //else if ((instID !== -1 && instID !== null) && (taskID !== -1 && taskID !== null)) {
            //    history.push(`/Installations/${response.note.installID}/Tasks/${response.note.taskID}`);
            //}
            else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                history.push(`/Services/${svcID}`);
            }
            else {
                history.push("/");
            }
        })
    }
    const submit = () => {
        setError("");
        if (noteName.trim() === "") {
            setError("A note name is required."); return;
        }
        if (noteDescript.trim() === "") {
            setError("A description is required."); return;
        }
        setLoading(true);
        // will need to pass in service id variable that is gotten from params
        CreateNote(props.global, noteID, noteName, noteDescript, noteTime, taskID, instID, userId, svcID, status, flag).then(response => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return;
            }
            if (noteID === -1) {
                if (instID !== -1 && instID !== null && instID !== undefined) {
                    history.push(`/Installations/${response.note.installID}`);
                }
                //else if ((instID !== -1 && instID !== null) && (taskID !== -1 && taskID !== null)) {
                //    history.push(`/Installations/${response.note.installID}/Tasks/${response.note.taskID}`);
                //}
                else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                    history.push(`/Services/${svcID}`);
                }
                else {
                    history.push("/");
                }
            }
            setMode("view");
        })
    }
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="display-area-header">
                {((instID !== null && instID !== -1 && instID !== undefined) && (taskID === null || taskID === -1 || taskID === undefined)) && (
                    <Link to={`/Installations/${instID}`} className="header-button">
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {((taskID !== null && taskID !== -1 && taskID !== undefined) && (instID !== null && instID !== -1)) && (
                    <Link to={`/Installations/${instID}/Tasks/${taskID}`} className="header-button">
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {(svcID !== null && svcID !== 1 && svcID !== undefined) && (
                    <Link to={`/Services/${svcID}`} className="header-button">
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                <form className="form" onSubmit={(e) => submitForm(e)} >
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Note Name</th>
                                <td>
                                    {mode === "create" ? (
                                        <input type="text" className="text-field" value={noteName} onChange={(e) => setNoteName(e.target.value)} required />
                                    ) : noteName}
                                </td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>
                                    {/*{mode !== "view" ? (*/}
                                    {/*    <input type="text" className="text-field" value={noteDescript} onChange={(e) => setNoteDescript(e.target.value)} />*/}
                                    {/*) : noteDescript}*/}
                                    {mode === "create" ? (
                                        <textarea className="text-field large-text-field" value={noteDescript} onChange={(e) => setNoteDescript(e.target.value)} required />
                                    ) : noteDescript}
                                </td>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <td>
                                    {noteTime_fmt}
                                </td>
                            </tr>
                            <tr>
                                <th>User</th>
                                <td>
                                    {mode !== "create" ? (
                                        userLN + ", " + userFN
                                    ) : ""}
                                </td>
                            </tr>
                            {canSeeInternal === true && (
                                <tr>
                                    <th>Flag</th>
                                    <td>
                                        {mode !== "view" ? (
                                            <div className="status-input-container">
                                                <select value={flag} className="select status-select"
                                                    onChange={(e) => setFlag(e.target.value)}>
                                                    {flags.map(f =>
                                                        <option key={"f-" + f.flagID} value={f.flagID}>
                                                            {f.flagName}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        ) : flagName}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </form>
                <div className="error">{error}</div>
                <div className="button-container">
                    {mode === "view" && (
                        <Link to="#" className="button"
                            onClick={() => setMode("edit")}>
                            Edit
                        </Link>
                    )}
                    {(mode === "view" && canSeeInternal === true) && (
                        <Link to="#" className="button"
                            onClick={deleteNote}>
                            Delete
                        </Link>
                    )}
                    {mode !== "view" && (
                        <>
                            <Link to="#" className="button"
                                onClick={cancelClick}>
                                Cancel
                            </Link>
                            {(mode === "edit" && canSeeInactive === true) && (
                                <Link to="#" className="button"
                                    onClick={save}>
                                    Save
                                </Link>
                            )}
                            {(mode === "edit" && canSeeInactive === false) && (
                                <Link to="#" className="button"
                                    onClick={deleteNote}>
                                    Delete
                                </Link>
                            )}
                            {mode === "create" && (
                                <Link to="#" className="button"
                                    onClick={submit}>
                                    Submit
                                </Link>
                            )}
                        </>
                    )}
                </div>
                {mode === "view" && (<hr />)}
                {mode === "view" && (
                    <h2 className="section-header">Attachments</h2>
                )}
                {mode === "view" && (
                    <AttachList global={props.global} instId={instID} servID={svcID} acctID={-1} noteID={noteID} />
                )}
                {mode === "view" && (<br />)}
            </div>
        </div>
    )
}
export default Note;