import { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { GetAttach, CreateAttach, SaveAttach, DeleteAttach } from '../API/InstallationAPI';
import Loader from './Loader.js';
//import axios from 'axios';
import { FilePond } from 'react-filepond';
import "filepond/dist/filepond.min.css";

const Attach = (props) => {
    let { instID, noteID, acctID, svcID, attachID } = useParams();   // add service id
    let history = useHistory();
    if (attachID === "new")
        attachID = -1;
    // consts for info
    const [attachName, setAttachName] = useState("");
    const [attachUpload, setAttachUpload] = useState();
    const [fileUpload, setFileUpload] = useState(null);
    const [attachDescript, setAttachDescript] = useState("");
    const [flag, setFlag] = useState(1);
    const [flagName, setFlagName] = useState("External");
    const [uploadTime, setUploadTime] = useState(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate());
    const [uploadTime_fmt, setUploadTime_fmt] = useState((new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear());
    const [userId, setUserId] = useState(-1);
    const [userFN, setUserFN] = useState("");
    const [userLN, setUserLN] = useState("");
    const [status, setStatus] = useState(0);
    const [statusName, setStatusName] = useState("Active");
    const [statuses, setStatuses] = useState([]);
    const [canSeeInternal, setCanSeeInternal] = useState(false);
    const [canSeeInactive, setCanSeeInactive] = useState(false);
    const [flags, setFlags] = useState([]);
    const [error, setError] = useState("");
    const [mode, setMode] = useState("edit");
    const [displayName, setDisplayName] = useState("Loading...");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        GetAttach(props.global, attachID).then(response => {
            if (!response.success) {
                history.push('/');
                setLoading(false);
                return;
            }
            setLoading(false);
            let attachInfo = response.attach;
            setFlags(response.flags);
            setStatuses(response.statuses);
            setCanSeeInactive(response.canSeeInactive);
            setCanSeeInternal(response.canSeeInternal);
            if (attachID === -1) {
                setDisplayName("New Attachment");
                setMode("create");
                return;
            }
            setDisplayName("Attachment");
            setAttachName(attachInfo.attachName);
            setAttachUpload(attachInfo.attachUpload);
            setAttachDescript(attachInfo.attachDescript);
            setFlag(attachInfo.flag);
            setFlagName(attachInfo.flagName);
            setUploadTime(attachInfo.uploadTime);
            setUploadTime_fmt(attachInfo.uploadTime_fmt);
            setUserId(attachInfo.userID);
            setUserFN(attachInfo.userFN);
            setUserLN(attachInfo.userLN);
            setStatus(attachInfo.status);
            setStatusName(attachInfo.statusName);
        })
    }, [props.global, history, mode, attachID])
    const cancelClick = () => {
        setError("");
        deleteAttach();
        if (instID !== -1 && instID !== null && instID !== undefined) {
            history.push(`/Installations/${instID}`);
        }
        else if (acctID !== -1 && acctID !== null && acctID !== undefined) {
            history.push(`/Accounts/${acctID}`);
        }
        else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
            history.push(`/Services/${svcID}`);
        }
        else {
            history.push("/");
        }
        // one for acct id
        return;
    }
    const submitForm = (e) => {
        e.preventDefault();
        if (mode === "create") {
            submit();
        }
        else if (mode === "edit") {
            save();
        }
    }
    const save = () => {
        setError("");
        if (attachUpload === null || attachUpload === undefined) {
            setError("A file is required."); return;
        }
        if (attachDescript.trim() === "") {
            setError("A description is required."); return;
        }
        setLoading(true);
        SaveAttach(props.global, attachID, attachName, attachUpload, attachDescript, flag, uploadTime, userId, instID, noteID, svcID, acctID, status).then(response => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return;
            }
            if (instID !== -1 && instID !== null && instID !== undefined) {
                history.push(`/Installations/${instID}`);
            }
            else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                history.push(`/Services/${svcID}`);
            }
            else if (acctID !== -1 && acctID !== null && acctID !== undefined) {
                history.push(`/Accounts/${acctID}`);
            }
            else {
                history.push("/");
            }
        })
    }
    const deleteAttachment = () => {
        SaveAttach(props.global, attachID, attachName, attachUpload, attachDescript, flag, uploadTime, userId, instID, noteID, svcID, acctID, 1).then(response => {
            setLoading(false);
            if (!response.success) {
                setError(response.message);
                return;
            }
            if (instID !== -1 && instID !== null && instID !== undefined) {
                history.push(`/Installations/${instID}`);
            }
            else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                history.push(`/Services/${svcID}`);
            }
            else if (acctID !== -1 && acctID !== null && acctID !== undefined) {
                history.push(`/Accounts/${acctID}`);
            }
            else {
                history.push("/");
            }
        })
    }
    const submit = () => {
        setError("");
        if (attachUpload === null || attachUpload === undefined) {
            setError("A file is required."); return;
        }
        if (attachDescript.trim() === "") {
            setError("A description is required."); return;
        }
        setLoading(true);
        if (attachDescript === "") {
            CreateAttach(props.global, fileUpload.attachmentID, attachName, fileUpload.attachUpload, fileUpload.attachDescript, flag, uploadTime, userId, instID, noteID, svcID, acctID, status).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (instID !== -1 && instID !== null && instID !== undefined) {
                    history.push(`/Installations/${response.attach.installID}`);
                }
                else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                    history.push(`/Services/${svcID}`);
                }
                else if (acctID !== -1 && acctID !== null && acctID !== undefined) {
                    history.push(`/Accounts/${acctID}`);
                }
                else {
                    history.push("/");
                }
            })
        }
        else if (attachDescript !== "") {
            CreateAttach(props.global, fileUpload.attachmentID, attachName, fileUpload.attachUpload, attachDescript, flag, uploadTime, userId, instID, noteID, svcID, acctID, status).then(response => {
                setLoading(false);
                if (!response.success) {
                    setError(response.message);
                    return;
                }
                if (instID !== -1 && instID !== null && instID !== undefined) {
                    history.push(`/Installations/${response.attach.installID}`);
                }
                else if (svcID !== -1 && svcID !== null && svcID !== undefined) {
                    history.push(`/Services/${svcID}`);
                }
                else if (acctID !== -1 && acctID !== null && acctID !== undefined) {
                    history.push(`/Accounts/${acctID}`);
                }
                else {
                    history.push("/");
                }
            })
        }
    }
    const token = localStorage.getItem("pools-token");
    const { apiBaseURL } = window['runConfig'];
    const serverconfig = {
        url: `${apiBaseURL}/api/Installation`,
        process: {
            url: "/UploadAttach",
            method: "post",
            headers: {
                Authorization: "Bearer " + token
            },
            onload: response => {
                const result = JSON.parse(response);
                if (!result.success) {
                    // TODO: Error handling
                    return;
                }
                setFileUpload(result.attach);
                setAttachName(result.attach.attachName);
            }
        }
    }
    // only when mode === "create"
    const deleteAttach = () => {
        if (mode === "create") {
            if (fileUpload !== null) {
                setLoading(true);
                setError("");
                if (attachDescript === "") {
                    DeleteAttach(props.global, fileUpload.attachmentID, fileUpload.attachName, fileUpload.attachUpload, fileUpload.attachDescript, flag, uploadTime, fileUpload.userID, instID, noteID, svcID, acctID, status).then(response => {
                        setLoading(false);
                        if (!response.success) {
                            setError(response.message);
                            return;
                        }
                    })
                }
                else if (attachDescript !== "") {
                    DeleteAttach(props.global, fileUpload.attachmentID, fileUpload.attachName, fileUpload.attachUpload, attachDescript, flag, uploadTime, fileUpload.userID, instID, noteID, svcID, acctID, status).then(response => {
                        setLoading(false);
                        if (!response.success) {
                            setError(response.message);
                            return;
                        }
                    })
                }
            }
        }
    }
    return (
        <div className="pools-table-container">
            {loading && <Loader />}
            <div className="display-area-header">
                {((instID !== null && instID !== -1 && instID !== undefined) && (noteID === null || noteID === -1 || noteID === undefined)) && (
                    <Link to={`/Installations/${instID}`} className="header-button" onClick={deleteAttach}>
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {((noteID !== null && noteID !== -1 && noteID !== undefined) && (instID !== null && instID !== -1 && instID !== undefined)) && (
                    <Link to={`/Installations/${instID}/Notes/${noteID}`} className="header-button" onClick={deleteAttach}>
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {((acctID !== null && acctID !== -1 && acctID !== undefined)) && (
                    <Link to={`/Accounts/${acctID}`} className="header-button" onClick={deleteAttach}>
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {((svcID !== null && svcID !== -1 && svcID !== undefined) && (noteID === null || noteID === -1 || noteID === undefined)) && (
                    <Link to={`/Services/${svcID}`} className="header-button" onClick={deleteAttach}>
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                {((svcID !== null && svcID !== -1 && svcID !== undefined) && (noteID !== null && noteID !== -1 && noteID !== undefined)) && (
                    <Link to={`/Services/${svcID}/Notes/${noteID}`} className="header-button" onClick={deleteAttach}>
                        <i className="fa-solid fa-square-xmark fa-2x"></i>
                    </Link>
                )}
                <h1>{displayName}</h1>
            </div>
            <div className="display-area-body">
                {/*<p>Check back later!</p>*/}
                <form className="form" onSubmit={(e) => submitForm(e)} >
                    <table className="pools-table pools-table-horizontal">
                        <tbody>
                            <tr>
                                <th>Attachment</th>
                                <td>
                                    {mode === "create" ? (
                                        <FilePond server={serverconfig} allowMultiple={false} onupdatefiles={setAttachUpload} onremovefile={deleteAttach}
                                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>' required />
                                    ) : (
                                        <a href={`${apiBaseURL}/api/Installation/GetFile?FileId=${attachID}`}>{attachName}</a>
                                    )}
                                    {/*<FilePond files={attachUpload} server={serverconfig} allowMultiple={false} onupdatefiles={setAttachUpload} labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>' />*/}
                                </td>
                            </tr>
                            <tr>
                                <th>Description</th>
                                <td>
                                    {mode === "create" ? (
                                        <textarea className="text-field large-text-field" value={attachDescript} onChange={(e) => setAttachDescript(e.target.value)} required />
                                    ) : attachDescript}
                                </td>
                            </tr>
                            <tr>
                                <th>Upload Date</th>
                                <td>
                                    {uploadTime_fmt}
                                </td>
                            </tr>
                            <tr>
                                <th>User</th>
                                <td>
                                    {mode === "edit" ? (
                                        userLN + ", " + userFN
                                    ) : ""}
                                </td>
                            </tr>
                            {canSeeInternal === true && (
                                <tr>
                                    <th>Flag</th>
                                    <td>
                                        {mode !== "view" ? (
                                            <div className="status-input-container">
                                                <select value={flag} className="select status-select"
                                                    onChange={(e) => setFlag(e.target.value)}>
                                                    {flags.map(f =>
                                                        <option key={"f-" + f.flagID} value={f.flagID}>
                                                            {f.flagName}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        ): flagName}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </form>
                <div className="error">{error}</div>
                <div className="button-container">
                    <Link to="#" className="button"
                        onClick={cancelClick}>
                        Cancel
                    </Link>
                    {(mode === "edit" && canSeeInactive === true) && (
                        <Link to="#" className="button"
                            onClick={save}>
                            Save
                        </Link>
                    )}
                    {(mode === "edit") && (
                        <Link to="#" className="button"
                            onClick={deleteAttachment}>
                            Delete
                        </Link>
                    )}
                    {mode === "create" && (
                        <Link to="#" className="button"
                            onClick={submit}>
                            Submit
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Attach;