import {Switch, Route} from 'react-router-dom';
import Login from './Login.js';
import Registration from './Registration.js';
import PasswordReset from './PasswordReset.js';

const UnauthorizedRoutes = (props) => {
    return !props.globalLoading && (
        <Switch>
            <Route path="/Register">
                <Registration global={props.global} />
            </Route>
            <Route path="/PasswordReset">
                <PasswordReset global={props.global} />
            </Route>
            <Route path="/">
                <Login global={props.global} />
            </Route>
        </Switch>
    )
}

export default UnauthorizedRoutes;