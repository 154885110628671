import APIRequest from './APIRequest.js';

export const GetEmployeeList = (global, includeInactive) => {
    return APIRequest({
        global: global,
        url: 'Employee/GetEmployeeList',
        method: 'post',
        body: {
            "includeInactive": includeInactive
        }
    })
}

export const GetEmployee = (global, id) => {
    return APIRequest({
        global: global,
        url: 'Employee/GetEmployee',
        method: 'post',
        body: {
            "userID": id
        }
    })
}

export const SaveEmployee = (global, id, firstName,
        lastName, primaryPhone, primaryPhoneType,
        secondaryPhone, secondaryPhoneType,
        email, active, roleId) => {
    return APIRequest({
        global: global,
        url: 'Employee/SaveEmployee',
        method: 'post',
        body: {
            "employee": {
                "userID": id,
                "firstName": firstName,
                "lastName": lastName,
                "primaryPhone": primaryPhone,
                "primaryPhoneTypeID": primaryPhoneType,
                "secondaryPhone": secondaryPhone,
                "secondaryPhoneTypeID": secondaryPhoneType,
                "email": email,
                "active": active,
                "roleID": roleId
            }
        }
    })
}