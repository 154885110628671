import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {GetNavigation} from '../API/NavigationAPI.js';
import Loader from './Loader.js';

const Navigation = (props) => {
    let location = useLocation();
    const [loc, setLoc] = useState("");
    const [loading, setLoading] = useState(false);
    const [navItems, setNavItems] = useState([]);
    useEffect(() => {
        setLoading(true);
        GetNavigation(props.global).then((response) => {
            setNavItems(response.navItems);
            setLoading(false);
        });
    }, [props.global]);
    useEffect(() => {
        setLoc(location);
    }, [location, loc])
    const getNavItemClass = (matchexpr) => {
        console.log(window.location.hash);
        if (window.location.hash.match(matchexpr))
        {
            return "nav-item nav-item-active";
        }
        return "nav-item";
    }
    return (
        <div className="navigation-container">
            {loading && <Loader />}
            {navItems.map(item => 
                <Link key={item.label} to={item.dest} className={
                    getNavItemClass(new RegExp(item.routeExpr, 'i'))}>
                    <i className={"fa-solid " + item.icon}></i>
                    <div className="nav-text">
                        {item.label}
                    </div>
                </Link>
            )}
        </div>
    )
}

export default Navigation;