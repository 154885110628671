import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Loader from '../Loader.js';
import { GetAccountServices } from '../../API/AccountAPI.js';

const AccountService = (props) => {
    const [loading, setLoading] = useState(true);
    const [svcCalls, setSvcCalls] = useState([]);
    let { id } = useParams();
    useEffect(() => {
        setLoading(true);
        GetAccountServices(props.global, id).then(response => {
            setLoading(false);
            setSvcCalls(response.svcCalls);
        }).catch(() => {
            setLoading(false);
        })
    }, [])
    return (
        <div className="widget-container account-service-container">
            {loading && <Loader />}
            {svcCalls.length === 0 ? (
                <div>
                    <p>
                        <i>No service details available.</i>
                    </p>
                    <Link to={`/Services/new/${id}`} className="button">
                        <i className="fa-solid fa-circle-plus"></i> Add Service
                    </Link>
                </div>
            ) : (
                <div>
                    <div className="acct-button-container">
                        <Link to={`/Services/new/${id}`} className="button">
                            <i className="fa-solid fa-circle-plus"></i> Add Service
                        </Link>
                    </div>
                    <div className="pools-table-container">
                        <table className="pools-table">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <th>Service Date</th>
                                    <th>Status</th>
                                </tr>
                                    {svcCalls.map(service => (
                                        <tr key={service.serviceID}>
                                            <td>
                                                <Link to={`/Services/${service.serviceID}`}>
                                                    {service.svcDescript}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/Services/${service.serviceID}`}>
                                                    {service.svcDate_fmt}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/Services/${service.serviceID}`}>
                                                    {service.svcStatusName}
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}
export default AccountService;