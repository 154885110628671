import {Link} from 'react-router-dom';
import Registration from './Registration.js';
const CreateAccountForm = () => {
    return (
        <div className="display-area-main">
            <div className="display-area-header">
                <Link to="/Accounts" className="header-button">
                    <i className="fa-solid fa-square-xmark fa-2x"></i>
                </Link>
                <h1>Create New Account</h1>
            </div>
            <div className="display-area-body">
                <Registration isCreateAcct={true} />
            </div>
        </div>
    )
}

export default CreateAccountForm;